import React from 'react';
import cn from 'classnames';
import { Form, Layout, Localization, Typography } from 'connex-cds';
import styled from 'styled-components';
import { useTicketContext } from '../../../../TicketContext';
import { CustomerCommentButton } from './CustomerCommentButton';

const { Column, Container } = Layout;
const { Subtitle } = Typography;
const { Translate } = Localization;

const Styled = styled(Column)`
  [data-testid='driverCommentValue'] {
    textarea {
      height: 70px;
      width: 100%;
      border: 0;
      background: transparent;
    }
  }

  .comments-mobile-container {
    padding: 5px 10px;

    .comment-section {
      padding: 5px;
    }
  }

  .material-compliance-enabled {
    padding: 5px 10px;

    .materialCompliance-section {
      padding: 5px;
    }
  }
`;

export const CommentsMobile = ({ companySetup, handleCommentClick, materialComplianceEnabled }) => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();

  return (
    <Styled className={cn('comments-mobile')}>
      {companySetup.data?.isDriverCommentEnabled && (
        <Column>
          <Container flex={1} className={cn('comments-mobile-container')}>
            <Subtitle>
              <Translate stringId="driverComment" data-testid="driverCommentLabel" />
            </Subtitle>
            <div className={cn('comment-section')}>
              <div data-testid="driverCommentValue">{values?.comments.driver}</div>
            </div>
          </Container>
        </Column>
      )}
      {companySetup.data?.isCustomerCommentEnabled && (
        <Column>
          <Container flex={1} className={cn('comments-mobile-container')} onClick={handleCommentClick}>
            <Subtitle>
              <Translate stringId="customerComment" data-testid="customerCommentLabel" />
            </Subtitle>
            <div className={cn('comment-section')}>
              {finalized &&
              !values?.comments.customer &&
              !(values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') ? (
                <CustomerCommentButton onClick={handleCommentClick} values={values} />
              ) : (
                <div data-testid="customerCommentValue">{values?.comments.customer}</div>
              )}
            </div>
          </Container>
        </Column>
      )}
      {materialComplianceEnabled && (
        <Column>
          <Container flex={1} className={cn('material-compliance-enabled')}>
            <Subtitle>
              <Translate stringId="compliance" data-testid="materialComplianceLabel" />
            </Subtitle>
            <div className={cn('materialCompliance-section')}>
              {!!values?.productCompliance?.consistenceIsCompliant ? (
                <i className={cn('icon fa-light fa-square-check')} />
              ) : (
                <i className={cn('icon fa-light fa-square')} />
              )}
              <Translate stringId="consistenceIsCompliantShort" data-testid="consistenceIsCompliantLabel" />
            </div>
            <div className={cn('materialCompliance-section')}>
              {!!values?.productCompliance?.airContentIsCompliant ? (
                <i className={cn('icon fa-light fa-square-check')} />
              ) : (
                <i className={cn('icon fa-light fa-square')} />
              )}
              <Translate stringId="airContentIsCompliantShort" data-testid="airContentIsCompliantLabel" />
            </div>
          </Container>
        </Column>
      )}
    </Styled>
  );
};
