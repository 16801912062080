import styled from 'styled-components';

export const Styled = styled.div`
  table {
    border: 1px solid #ccc;
    width: 100%;
    border-collapse: collapse;
    cursor: pointer;

    tr {
      &.header-row {
        font-weight: bold;
        background-color: var(--color-background-container);
      }

      td {
        border: none;

        &.header-cell {
          padding-left: 5px;
          width: 256px;
        }

        &.data-cell {
          display: flex;
        }
      }
    }
  }
`;
