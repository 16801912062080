import React from 'react';
import cn from 'classnames';
import { ListItem } from './ListItem';

export const ListMobile = ({ data, ...props }) => {
  return (
    <div className={cn('list-mobile')}>
      {data?.map?.((item, idx) => (
        <ListItem {...props} item={item} idx={idx} />
      ))}
    </div>
  );
};
