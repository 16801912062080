import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { MaterialIcon } from '../../../components/icons';
import { Translate, useTranslateMessage } from '../../../components/localization';
import defaultMenuLogo from '../../../assets/cai_logo_2c_white.svg';

import { SidebarContainer, MobileSubMenuContainer, StyledSubMenuContainer } from './styles';
import Box from '@mui/material/Box';
import SubMenuContent from './SubMenuContent';
import { Divider } from '@mui/material';

const SubMenuContainer = ({ subMenuProps }) => {
  return (
    <StyledSubMenuContainer
      className={cn('menu-sidebar', {
        'activated-sub-menu': subMenuProps.isSubMenuOpen,
      })}
      onMouseLeave={() => subMenuProps.clearActivatedSubMenu()}
    >
      <SubMenuContent {...subMenuProps} />
    </StyledSubMenuContainer>
  );
};

const SidebarHeader = ({ isMobile, initialMenuConfig, toggleSidebar, clearActivatedSubMenu }) => (
  <div
    className={cn('sidebar-logo-container', {
      'is-mobile': isMobile,
    })}
  >
    <img src={initialMenuConfig?.menuLogo || defaultMenuLogo} alt="menu-logo" />
    {isMobile && (
      <MaterialIcon
        iconName={'MenuOpen'}
        onClick={() => {
          toggleSidebar() && clearActivatedSubMenu();
        }}
        className="menu-icon"
      />
    )}
  </div>
);

const SidebarContent = ({
  isMobile,
  handleMenuItemClick,
  toggleSidebar,
  initialMenuConfig,
  preparedConfig,
  amIActive,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activatedSubMenu, setActivatedSubMenu] = useState(null);
  const [enableSearch, setEnableSearch] = useState(false);
  const translateMessage = useTranslateMessage();
  const toggleSubMenuOpen = () => {
    setIsSubMenuOpen(prevState => !prevState);
  };

  const activateSubMenu = useCallback(option => {
    if (option?.children?.length) {
      setActivatedSubMenu(option);
      setIsSubMenuOpen(true);
    }
  }, []);

  const clearActivatedSubMenu = useCallback(() => {
    setActivatedSubMenu(null);
    setIsSubMenuOpen(false);
  }, []);

  const handleOptionClick = useCallback(
    (option, hasChildren) => {
      if (hasChildren) {
        if (isMobile) {
          activateSubMenu(option);
          setIsSubMenuOpen(true);
          setEnableSearch(option?.enableSearch);
        } else {
          const homepageChild = option.children.find(child => child?.isHomePage);
          if (homepageChild) {
            handleMenuItemClick(homepageChild.path);
          }
        }
      } else {
        handleMenuItemClick(option.path);
        if (isMobile) toggleSidebar();
      }
    },
    [activateSubMenu, handleMenuItemClick, isMobile, setIsSubMenuOpen, setEnableSearch, toggleSidebar]
  );

  const handleOptionMouseOver = useCallback(
    (option, hasChildren) => {
      clearActivatedSubMenu();
      if (hasChildren && !isMobile) {
        activateSubMenu(option);
        setEnableSearch(option?.enableSearch);
      }
    },
    [activateSubMenu, clearActivatedSubMenu, isMobile, setEnableSearch]
  );

  const subMenuProps = {
    activatedSubMenu,
    isMobile,
    clearActivatedSubMenu,
    enableSearch,
    toggleSubMenuOpen,
    amIActive,
    handleMenuItemClick,
    toggleSidebar,
    isSubMenuOpen,
  };

  return (
    <>
      <SidebarContainer
        className={cn('sidebar', {
          'has-children': activatedSubMenu?.children,
          'siSidebarContainerdebar-secondary': initialMenuConfig?.sidebarVariant === 'secondary',
          'is-mobile': isMobile,
        })}
      >
        <SidebarHeader
          isMobile={isMobile}
          initialMenuConfig={initialMenuConfig}
          toggleSidebar={toggleSidebar}
          clearActivatedSubMenu={clearActivatedSubMenu}
        />
        <div
          className={cn('sidebar-options-container', {
            hidden: isSubMenuOpen && isMobile,
          })}
        >
          {preparedConfig?.map((option, index) => {
            const hasChildren = Boolean(option?.children?.length);
            const tooltipStringId = option?.tooltipStringId;
            const isDivider = option?.id === 'divider';

            if (isDivider) {
              return <Divider sx={{ width: '80%' }} key={index} variant="middle" />;
            }

            return (
              <div
                key={index + option?.id}
                data-testid={option?.id}
                className={cn('option', {
                  option__active: amIActive(option),
                })}
                onClick={() => handleOptionClick(option, hasChildren)}
                onMouseOver={() => handleOptionMouseOver(option, hasChildren)}
              >
                {isMobile ? (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingLeft: '10px',
                    }}
                  >
                    <Box
                      component="div"
                      sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}
                    >
                      {option?.iconName ? <MaterialIcon iconName={option?.iconName} className="icon" /> : option.icon}
                      {<Translate className="label" stringId={option?.labelStringId} />}
                    </Box>
                    {hasChildren && <MaterialIcon iconName={'ArrowForwardRounded'} />}
                  </Box>
                ) : (
                  <>
                    {option?.iconName ? <MaterialIcon iconName={option?.iconName} className="icon" /> : option.icon}
                    {!hasChildren && !isMobile && tooltipStringId ? (
                      <div className="tooltip">{translateMessage(tooltipStringId)}</div>
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
        </div>
        {isMobile &&
          (activatedSubMenu ? (
            <MobileSubMenuContainer>
              <div
                className={cn('menu-sidebar', {
                  hidden: !isSubMenuOpen && isMobile,
                })}
              >
                <SubMenuContent
                  toggleSubMenuOpen={toggleSubMenuOpen}
                  amIActive={amIActive}
                  handleMenuItemClick={handleMenuItemClick}
                  toggleSidebar={toggleSidebar}
                  {...subMenuProps}
                />
              </div>
            </MobileSubMenuContainer>
          ) : null)}
      </SidebarContainer>
      <SubMenuContainer subMenuProps={subMenuProps} />
    </>
  );
};

export default SidebarContent;
