import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { WizardSteps } from '../WizardSteps';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const DesktopWizardSteps = props => {
  return (
    <Styled className={cn('desktop-wizard-steps')}>
      <WizardSteps {...props} />
    </Styled>
  );
};
