import styled from '@emotion/styled';
import { css } from 'styled-components';

export const Table = styled.div`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  display: grid;
  grid-template-areas:
    'head-fixed'
    'body-scrollable'
    'foot-fixed';
  grid-template-rows: min-content auto min-content;
  border: 1px solid #dddddd;
  border-radius: 5px;
  overflow: hidden;
`;

export const StyledHeader = styled.div`
  grid-area: head-fixed;
  .products-row {
    font-weight: bold;
    border-bottom: 1px solid #abaaab;

    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
`;

export const TableBody = styled.div`
  grid-area: body-scrollable;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas con igual ancho */
  border-bottom: 1px solid #ddd;
  align-items: flex-start;
`;

export const TableCell = styled.div`
  border-right: 1px solid #ddd;
  padding: 5px;
  box-sizing: border-box;
  overflow-x: auto;
  width: 100%;
  flex: 1 1 0;
  max-width: 100%;

  &:last-child {
    border-right: none;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
`;

export const Label = styled.span`
  font-weight: bold;
`;

export const Value = styled.span`
  color: ${({ theme }) => {
    if (theme === 'dark') return '#F3F3F3;';
    return '#050505;';
  }};
`;

export default css`
  transition: all 0.3s;
  border-radius: var(--radius-container);

  color: ${({ theme }) => {
    if (theme === 'dark') return '#F3F3F3;';
    return '#050505;';
  }};
  border: 1px solid var(--color-border-container);
  box-shadow: var(--boxShadow-container);
  overflow: hidden;
`;
