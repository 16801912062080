import React from 'react';
import cn from 'classnames';
import { Localization, Theme } from 'connex-cds';
import { get, isString, toString } from 'lodash';
import styled from 'styled-components';

import style, { StyledBody, StyledFooter, StyledHeader, StyledTable } from './Style';

const { Translate } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'addedItemsGrid';

const getValue = ({ paths, results, rowIndex, translateMessage }) => {
  if (!paths?.length) {
    return '';
  }

  const [first, second] = paths
    ?.map(path => (isString(path) ? path?.replace('.0.', `.${rowIndex}.`) : ''))
    ?.map(path => get(results, path, ''));

  if (!second) {
    return toString(first);
  }
  return translateMessage(`${second}_short`, { value: first });
};

export const MaterialSummaryDesktop = ({ source, results }) => {
  const translateMessage = Localization.useTranslateMessage();
  const { themeName: theme } = Theme.useThemeContext();
  const rows = results?.consumptions?.map(consumption => ({ ...consumption }));

  return (
    <Styled data-testid={testId} className={cn('products-table-section')} theme={theme}>
      <StyledTable className={cn('products-table')}>
        <StyledHeader>
          <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="materialSummary" data-testid="products-label" />
          </div>
        </StyledHeader>
        <StyledBody>
          <div className={cn('products-row table-header')} data-testid={`${testId}-second-header-row`}>
            {source?.map(_data => (
              <Translate stringId={`${_data?.labelStringId}`} data-testid="amount-label" />
            ))}
          </div>

          {rows?.map((_row, rowIndex) => (
            <div
              className={cn('products-row')}
              key={`${testId}-row-${rowIndex}-column-product`}
              data-testid={`${testId}-row-${rowIndex}`}
            >
              {source?.map((data, cellIndex) => (
                <div
                  key={`${testId}-row-${cellIndex}-column-product`}
                  data-testclass="cell-column-product"
                  data-testid={`${testId}-row-${rowIndex}-column-product`}
                  style={{ alignItems: 'center' }}
                >
                  {getValue({ paths: data?.paths, results, translateMessage, rowIndex })}
                </div>
              ))}
            </div>
          ))}
        </StyledBody>
        <StyledFooter>
          <div className={cn('products-row')} />
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
