import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from 'connex-cds';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { admin, masterData as masterDataApi, masterData, mobileTicket } from '../../../../api';

export const useCreateItem = () => {
  const { entityRef, typeId: itemType } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, itemType];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.createSetupData({ entityRef, itemType, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateItem = () => {
  const { entityRef, typeId: itemType } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, itemType];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateSetupData({ entityRef, itemType, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useCreateSetupItem = () => {
  const { entityRef, typeId } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.createMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateSetupItem = () => {
  const { entityRef, typeId } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useSetupByTypeId = () => {
  const { entityRef, typeId } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, typeId],
    queryFn: () => masterData.getMasterData({ entityRef, typeId }),
  });
};

export const useCompanySetup = () => {
  const { entityRef, truckNumber } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'entity-mt'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'entity-mt', truckNumber }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};

export const useUnloadingMethodSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'unloading-method'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'unloading-method', truckNumber }),
    staleTime: Infinity,
  });
};

export const useProductSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'product'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'product', truckNumber }),
    staleTime: Infinity,
  });
};

export const useProductTypeSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'product-type'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'product-type', truckNumber }),
    staleTime: Infinity,
  });
};

export const useUomSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', 'uom'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'uom', truckNumber }),
    staleTime: Infinity,
  });
};

export const useDriverCommentSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'driver-comment'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'driver-comment', truckNumber }),
    staleTime: Infinity,
  });
};

export const useVehicleSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'vehicle'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'vehicle', truckNumber }),
    staleTime: Infinity,
  });
};

export const useVehicleTypeSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'vehicle-type'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'vehicle-type', truckNumber }),
    staleTime: Infinity,
  });
};

export const useReasonCodeSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'reason-code'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'reason-code', truckNumber }),
    staleTime: Infinity,
  });
};

export const useCompany = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['admin', 'entities', entityRef],
    queryFn: () => admin.getEntity({ entityRef, truckNumber }),
    staleTime: Infinity,
  });
};

export const useEntityTranslations = () => {
  const [entityRef, setEntityRef] = React.useState();
  const [loading, setLoading] = React.useState();
  const [query, setQuery] = React.useState();

  async function fetchTranslations() {
    setLoading(true);
    const _query = await admin.getTranslations({ entityRef });
    setQuery(_query);
    setLoading(false);
  }

  React.useEffect(() => {
    if (entityRef && entityRef !== 'register') {
      fetchTranslations();
    }
  }, [entityRef]);

  return {
    setEntityRef,
    loading,
    ...query,
    data: query,
  };
};

export const useEntities = () => {
  const { user } = User.useUserContext();
  const { isPlatformSupport, isPlatformAdmin } = User.usePermissions();

  const { profileRef } = user;

  const queryParams = {
    permission: isPlatformSupport || isPlatformAdmin || false,
  };

  return useQuery({
    queryKey: ['entities', profileRef, queryString.stringify(queryParams)],
    queryFn: () => mobileTicket.getAppEntities({ profileRef, queryParams }),
    staleTime: Infinity,
  });
};

export const useDataRetentionSetup = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'data-retentions'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, itemType: 'data-retentions' }),
    staleTime: Infinity,
  });
};

export const useCarriersSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'carriers'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, itemType: 'carriers', truckNumber }),
    staleTime: Infinity,
  });
};

export const useMaterialComplianceSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'concrete-classes'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'concrete-classes', truckNumber }),
    staleTime: Infinity,
  });
};

export const useFormsAndWaiversSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'forms-and-waivers'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'forms-and-waivers', truckNumber }),
    staleTime: Infinity,
  });
};

export const useTemplatesSetup = () => {
  const { entityRef, truckNumber } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'templates-mt'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'templates-mt', truckNumber }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};

export const useTemplatesPASetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef, 'templates-pa'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'templates-pa' }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};

export const useExternalTemplatesSetup = () => {
  const { user } = User.useUserContext();
  const { profileRef } = user;
  const [entityRef, setEntityRef] = React.useState();
  const [loading, setLoading] = React.useState();
  const [query, setQuery] = React.useState();

  async function fetchTemplates() {
    setLoading(true);
    const _query = await mobileTicket.getMtTemplatesExternal({ profileRef, entityRef });
    setQuery(_query);
    setLoading(false);
  }

  React.useEffect(() => {
    if (entityRef) {
      fetchTemplates();
    }
  }, [entityRef]);

  return {
    setEntityRef,
    loading,
    ...query,
    data: query,
  };
};

export const useRiskAssessmentSetup = () => {
  const { entityRef, truckNumber } = useParams();

  return useQuery({
    queryKey: ['setup', entityRef, 'assessment-risk'],
    queryFn: () => masterDataApi.getMasterData({ entityRef, typeId: 'assessment-risk', truckNumber }),
    staleTime: Infinity,
  });
};

export const useGetSummary = itemRef => {
  const { entityRef } = useParams();
  const [loading, setLoading] = React.useState();
  const [query, setQuery] = React.useState();

  async function fetchSummary() {
    setLoading(true);
    const _query = await masterDataApi.getMasterDataItem({ entityRef, typeId: 'summary', itemRef });
    setQuery(_query);
    setLoading(false);
  }

  React.useEffect(() => {
    if (entityRef && itemRef) {
      fetchSummary();
    }
  }, [entityRef, itemRef]);

  return {
    loading,
    ...query,
    data: query,
    refetch: fetchSummary,
  };
};

export const useGetPublicSummary = itemRef => {
  const [loading, setLoading] = React.useState();
  const [query, setQuery] = React.useState();

  async function fetchSummary() {
    setLoading(true);
    const _query = await masterDataApi.getPublicSummary({ itemRef });
    setQuery(_query);
    setLoading(false);
  }

  React.useEffect(() => {
    if (itemRef) {
      fetchSummary();
    }
  }, [itemRef]);

  return {
    loading,
    ...query,
    data: query,
    refetch: fetchSummary,
  };
};

export const useCreateSummary = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, 'summary'];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.createMasterData({ entityRef, typeId: 'summary', item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateSummary = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, 'summary'];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateMasterData({ entityRef, typeId: 'summary', item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useMixByProductSetup = () => {
  const { entityRef } = useParams();
  const [productRef, setProductRef] = useState('');

  const query = useQuery({
    queryKey: ['setup', entityRef, 'mix', productRef],
    queryFn: () => {
      if (productRef) {
        return masterDataApi.getMasterDataItem({ entityRef, typeId: 'mixes', itemRef: productRef });
      }

      return null;
    },
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data,
    setProductRef,
  };
};

export const useDriverSetup = () => {
  const { entityRef } = useParams();
  const [driverRef, setDriverRef] = useState('');

  const query = useQuery({
    queryKey: ['setup', entityRef, 'driver', driverRef],
    queryFn: () => {
      if (driverRef) {
        return masterDataApi.getMasterDataItem({ entityRef, typeId: 'drivers', itemRef: driverRef });
      }

      return null;
    },
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data,
    setDriverRef,
  };
};
