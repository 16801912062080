import cn from 'classnames';
import { Core, Form, Localization, toUtcIso, Layout, Drawer } from 'connex-cds';
import { DateTime } from 'luxon';
import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import style from './style';
import { find } from 'lodash';
import { useTicketContext } from '../../../../TicketContext';

const Styled = styled.div`
  ${style}
`;

const { Row } = Layout;
const { Button } = Core;

export const ProducerSignatureCanvas = ({ producerSignature, onChange, ticketId, signatureType }) => {
  const signaturePadRef = React.useRef();
  const { formatLongDateTime } = Localization.useFormat();
  const { ticket } = useTicketContext();
  const {
    Components: { ProducerSignatureContact },
    setFieldValue,
    values,
  } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  React.useEffect(() => {
    if (signaturePadRef.current) {
      const canvas = signaturePadRef.current.signaturePad.canvas;
      canvas.width = 930;
      canvas.height = 225;
    }
  }, []);

  const handleStrokeEnd = React.useCallback(() => {
    const now = toUtcIso(DateTime.now());

    onChange({
      ...(values?.producerSignature || {}),
      timestamp: now,
      formattedTimestamp: formatLongDateTime(now),
      image: signaturePadRef?.current?.signaturePad?.toDataURL?.(),
      ticketId,
    });
  }, [formatLongDateTime, onChange, ticketId, values?.producerSignature]);

  React.useEffect(() => {
    if (producerSignature?.image) {
      signaturePadRef.current.fromDataURL(producerSignature.image);
    }
  }, [producerSignature, signaturePadRef]);

  React.useEffect(() => {
    const ref = signaturePadRef?.current;
    signaturePadRef?.current?.signaturePad?.addEventListener?.('endStroke', handleStrokeEnd);
    return () => ref?.removeEventListener?.('endStroke', handleStrokeEnd);
  }, [handleStrokeEnd, signaturePadRef]);

  React.useEffect(() => {
    if (!values?.producerSignature?.signerName) {
      setFieldValue('producerSignature', { ...values?.producerSignature });
    }
  }, [setFieldValue, ticket?.destination?.contacts]);

  const handleClear = React.useCallback(() => {
    signaturePadRef?.current?.clear?.();
    onChange({
      ...(values?.producerSignature || {}),
      timestamp: undefined,
      formattedTimestamp: undefined,
      image: undefined,
    });
  }, [values?.producerSignature]);

  return (
    <Styled className={cn('signature')}>
      <ProducerSignatureContact />
      <div className="signature-outer x">
        <Core.Button onClick={handleClear} stringId="clear-signature-button" data-testid="clear-signature-button" />
        <div className="signature-inner x">
          <SignaturePad redrawOnResize={true} ref={signaturePadRef} width={930} height={225} />
        </div>
      </div>
      <Row className="actions producer">
        <Row>
          <Button
            type="primary"
            danger
            onClick={() => closeDrawer()}
            stringId="cancel-signature-button"
            data-testid="cancel-signature-button"
          />
          <Button
            type="primary"
            disabled={false}
            onClick={() => closeDrawer()}
            stringId="submit-signature-button"
            data-testid="ok-button"
          />
        </Row>
      </Row>
    </Styled>
  );
};
