import React from 'react';
import { Form, Localization } from 'connex-cds';
import { Styled } from './style';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();
  const translateMessage = Localization.useTranslateMessage();

  if (!values?.returnReason && !values?.driverDidNotAdd) {
    return null;
  }

  const translateReason = (returnConcreteOnTruck, returnReason) => {
    if (returnConcreteOnTruck?.value && returnReason) {
      const key = `SETUP_REASON-CODE_${values?.returnReason?.id}`.replace(/\s/gi, '_').toUpperCase();
      const translation = translateMessage(key);

      if (translation && translation !== key) {
        return translation;
      }

      if (returnReason?.label) {
        return returnReason?.label;
      }

      if (returnReason?.description) {
        return returnReason?.description;
      }
    }

    return '';
  };

  return (
    <Styled className="returned-material-mobile">
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            <Localization.Translate stringId="returnedMaterial" data-testid="quantity-label" />
          </td>
        </tr>
        <tr>
          <td className="header-cell" data-testid={`${testId}-activity-value`}>
            <Localization.Translate stringId="quantity" data-testid="quantity-label" />
          </td>
          <td data-testid={`${testId}-concrete-value`}>
            <Localization.Uom
              uom={
                values?.returnConcreteOnTruck?.value
                  ? values?.returnConcreteOnTruck
                  : { value: '0', uomCode: values?.returnConcreteOnTruck?.uomCode }
              }
            />
          </td>
        </tr>

        <tr>
          <td className="header-cell">
            <Localization.Translate stringId="reason" data-testid="reason-label" />
          </td>
          <td data-testid={`${testId}-reason-value`}>
            {translateReason(values?.returnConcreteOnTruck, values?.returnReason)}
          </td>
        </tr>
      </table>
    </Styled>
  );
};
