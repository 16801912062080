import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Layout } from 'connex-cds';
import { WizardSteps } from '../WizardSteps';
import style from './style';
const { Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const MobileWizardSteps = props => {
  return (
    <Styled>
      <Container className={cn('mobile-wizard-steps')}>
        <WizardSteps {...props} labelClassname={props?.steps?.length > 4 ? 'shortened-label' : ''} />
      </Container>
    </Styled>
  );
};
