import React, { useContext } from 'react';
import { isObject } from 'lodash';
import { useParams } from 'react-router-dom';
import { mobileTicket } from '../../../../../api';
import { cacheItem, clearCachedItem, getCachedItem } from '../../../../../util/cache';
import { usePostMessageListener } from './usePostMessageListener';
import { Logger } from '../../../../../util/log/logger';

const CACHE_KEY = 'tickets';

const DataStoreContext = React.createContext();

export const useDataStore = () => {
  const context = useContext(DataStoreContext);
  if (!context) {
    throw new Error(`useDataStore cannot be rendered outside of the DataStore context provider`);
  }
  return context;
};

// The assumption at this time is that it will be necessary for only one ticket to queue while waiting
// to come back online.

export const DataStore = ({ children }) => {
  const { entityRef } = useParams();
  const [isOnline, setIsOnline] = React.useState(true);

  const listener = React.useCallback(event => {
    let message;

    if (isObject(event?.data)) {
      message = event?.data;
    } else {
      try {
        message = JSON.parse(event?.data);
      } catch (e) {
        message = event?.data;
      }
    }

    if (message?.type === 'online') {
      setIsOnline(message?.value);
    }
  }, []);

  usePostMessageListener(listener);

  const updateTicket = ({ entityRef, ticket }) => {
    try {
      const result = mobileTicket.updateTicket({ entityRef, ticket });
      Logger.debug(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/datastore/DataStore.js - updateTicket Success: ${JSON.stringify(
          result
        )}`
      );
      return result;
    } catch (error) {
      Logger.error(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/datastore/DataStore.js - updateTicket Error: ${error.message}`
      );
      return Promise.reject(error);
    }
  };

  const processQueue = React.useCallback(async () => {
    const tickets = getCachedItem(CACHE_KEY);
    Logger.debug(`Tickets retrieved from cache: ${JSON.stringify(tickets)}`);
    if (tickets) {
      Logger.debug(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/DataStore.js - processQueue: ${JSON.stringify(
          tickets
        )}`
      );
      const result = await Promise.all(tickets.map(ticket => updateTicket({ entityRef, ticket })));
      Logger.debug(`Result from updateTicket in processQueue: ${JSON.stringify(result)}`);

      if (result.some(value => value === undefined)) {
        Logger.debug('Some tickets failed to update');

        return false;
      }
      clearCachedItem(CACHE_KEY);
      Logger.debug('Cache cleared');

      return true;
    }
    Logger.debug('No tickets in cache');
    return false;
  }, [entityRef]);

  //the 'online' message from trackIt is unreliable so try every minute instead
  React.useEffect(() => {
    Logger.debug(`processQueue has changed`);
    const intervalId = setInterval(async () => {
      const success = await processQueue();
      Logger.debug(`processQueue returned: ${success}`); // Debug statement added
    }, 60000);

    return () => {
      Logger.debug('Cleanup function called'); // Debug statement added
      clearInterval(intervalId);
    };
  }, [processQueue]);

  const submitTicket = React.useCallback(
    async ticket => {
      if (isOnline) {
        const result = await updateTicket({ entityRef, ticket });
        Logger.debug(`Result from updateTicket in submitTicket: ${JSON.stringify(result)}`);
        if (result === undefined || JSON.stringify(result) === '{}') {
          Logger.debug(
            `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/DataStore.js - we are not online, caching ticket: ${JSON.stringify(
              ticket
            )}`
          );
          const ticketsInCache = getCachedItem(CACHE_KEY);
          Logger.debug(`Tickets retrieved from cache: ${JSON.stringify(ticketsInCache)}`);
          const tickets = ticketsInCache ?? [];
          if (!tickets.some(t => t.ticketRef === ticket.ticketRef)) {
            cacheItem(CACHE_KEY, [...tickets, ticket]);
            Logger.debug(`Ticket added to cache: ${JSON.stringify([...tickets, ticket])}`);
          }
          return Promise.resolve({ queued: true });
        }

        return result;
      }

      Logger.debug(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/DataStore.js - we are offline, caching ticket: ${JSON.stringify(
          ticket
        )}`
      );
      const ticketsInCache = getCachedItem(CACHE_KEY);
      Logger.debug(`Tickets retrieved from cache: ${JSON.stringify(ticketsInCache)}`);
      const tickets = ticketsInCache ?? [];
      if (!tickets.some(t => t.ticketRef === ticket.ticketRef)) {
        cacheItem(CACHE_KEY, [...tickets, ticket]);
        Logger.debug(`Ticket added to cache: ${JSON.stringify([...tickets, ticket])}`);
      }
      return Promise.resolve({ queued: true });
    },
    [entityRef, isOnline]
  );

  return <DataStoreContext.Provider value={{ submitTicket, isOnline }}>{children}</DataStoreContext.Provider>;
};
