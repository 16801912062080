import React, { useEffect, useMemo } from 'react';
import { Form, Responsive } from 'connex-cds';
import { useMaterialComplianceSetup } from '../../../MasterDataProvider';
import { MaterialComplianceMobile } from './MaterialComplianceMobile';
import { MaterialComplianceDesktop } from './MaterialComplianceDesktop';

const filterCompliance = type => compliance => compliance?.classType === type;

export const MaterialCompliance = () => {
  const materialComplianceSetup = useMaterialComplianceSetup();

  const { values, setFieldValue } = Form.useFormContext();

  const activeMaterialComplianceSetup = useMemo(() => {
    if (materialComplianceSetup?.isSuccess && materialComplianceSetup?.data?.length) {
      return materialComplianceSetup?.data?.filter?.(compliance => compliance?.status === 'ACTIVE');
    }

    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const airUom = useMemo(
    () => activeMaterialComplianceSetup?.filter?.(filterCompliance('AIR'))?.[0]?.uomCode,
    [activeMaterialComplianceSetup]
  );

  const consistenceUom = useMemo(
    () => activeMaterialComplianceSetup?.filter?.(filterCompliance('CONSISTENCE'))?.[0]?.uomCode,
    [activeMaterialComplianceSetup]
  );

  const airTempUom = useMemo(
    () => activeMaterialComplianceSetup?.filter?.(filterCompliance('AIR_TEMP'))?.[0]?.uomCode,
    [activeMaterialComplianceSetup]
  );

  const concreteTempUom = useMemo(
    () => activeMaterialComplianceSetup?.filter?.(filterCompliance('CONCRETE_TEMP'))?.[0]?.uomCode,
    [activeMaterialComplianceSetup]
  );

  const cylinderUom = useMemo(
    () => activeMaterialComplianceSetup?.filter?.(filterCompliance('CYLINDERS'))?.[0]?.uomCode,
    [activeMaterialComplianceSetup]
  );

  const consistenceDisabled = useMemo(() => {
    return !values?.productCompliance?.consistenceValue?.value;
  }, [values?.productCompliance?.consistenceValue]);

  const airDisabled = useMemo(() => {
    return !values?.productCompliance?.airContentValue?.value;
  }, [values?.productCompliance?.airContentValue]);

  useEffect(() => {
    if (!consistenceDisabled || !airDisabled) {
      setFieldValue('productCompliance.dateTest', new Date().toISOString());
    }
  }, [values?.productCompliance?.airContentValue, values?.productCompliance?.consistenceValue]);

  return (
    <Responsive>
      <MaterialComplianceMobile
        airUom={airUom}
        consistenceUom={consistenceUom}
        airTempUom={airTempUom}
        concreteTempUom={concreteTempUom}
        cylinderUom={cylinderUom}
        consistenceDisabled={consistenceDisabled}
        airDisabled={airDisabled}
      />
      <MaterialComplianceDesktop
        airUom={airUom}
        consistenceUom={consistenceUom}
        airTempUom={airTempUom}
        concreteTempUom={concreteTempUom}
        cylinderUom={cylinderUom}
        consistenceDisabled={consistenceDisabled}
        airDisabled={airDisabled}
      />
    </Responsive>
  );
};
