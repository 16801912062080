import { css } from 'styled-components';

export default css`
  &&& {
    .products-and-services.column {
      margin-right: 5px;
    }

    .scrollable.column {
      overflow-y: auto;
    }

    .header-acceptance-desktop {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        &:first-child {
          margin-right: 20px;
        }
      }

      h3 {
        margin: 0;
      }
    }
  }
`;
