import React from 'react';
import { Form, Drawer, Core, Responsive } from 'connex-cds';
import { useTicketContext } from '../../../../TicketContext';
import { DynamicWaiverModal } from './DynamicWaiverModal';
import { DynamicWaiverMobile } from './DynamicWaiverMobile';
import { DynamicWaiverDesktop } from './DynamicWaiverDesktop';

export const DynamicWaiver = () => {
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const {
    Components: { DynamicWaiverSignature },
    setFieldValue,
    values,
  } = Form.useFormContext();
  const { finalized } = useTicketContext();

  const disableDynamicWaiverButton = React.useMemo(() => {
    return values?.dynamicWaiverDeclined || values?.dynamicWaiverSignature?.image;
  }, [values?.dynamicWaiverDeclined, values?.dynamicWaiverSignature]);

  const dynamicWaiverSignatureImage = React.useMemo(() => {
    return values?.dynamicWaiverSignature?.image;
  }, [values?.dynamicWaiverSignature?.image]);

  const handleDynamicWaiverClick = React.useCallback(() => {
    openDrawer({
      onClose: () => {
        setFieldValue('dynamicWaiverDeclined', '');
        setFieldValue('dynamicWaiverSignature', {
          signerName: '',
        });
        closeDrawer();
      },
      titleStringId: 'dynamicWaiver',
      component: <DynamicWaiverModal />,
      width: '100%',
    });
  }, [openDrawer, setFieldValue, closeDrawer, values?.dynamicWaiverSignature]);

  const showField = () => {
    if (dynamicWaiverSignatureImage && finalized && !['ACCEPTED', 'REJECTED'].includes(values?.customerStatus)) {
      return <DynamicWaiverSignature />;
    }

    if (dynamicWaiverSignatureImage && finalized) {
      return <DynamicWaiverSignature />;
    }

    if (!dynamicWaiverSignatureImage && finalized && !['ACCEPTED', 'REJECTED'].includes(values?.customerStatus)) {
      return (
        <Core.Button
          type="primary"
          stringId="dynamicWaiver"
          onClick={handleDynamicWaiverClick}
          data-testid="dynamicWaiver-button"
          disabled={disableDynamicWaiverButton || values?.realTimeIsLoading}
        />
      );
    }
  };

  return (
    <Responsive>
      <DynamicWaiverMobile showField={showField} />
      <DynamicWaiverDesktop showField={showField} />
    </Responsive>
  );
};
