import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Layout, Typography, Localization } from 'connex-cds';
import style from './style';

const { Subtitle } = Typography;
const { Translate } = Localization;
const { Column, Row, Container } = Layout;
const Styled = styled(Column)`
  ${style}
`;

export const DynamicWaiverDesktop = ({ showField }) => {
  const field = showField();

  return (
    <Styled className={cn('dynamic-waiver')}>
      <Row flex={1}>
        <Column>
          <Container flex={1} className={cn('dynamic-waiver-container')}>
            <Subtitle>
              <Translate stringId="dynamicWaiver" data-testid="dynamicWaiverLabel" />
            </Subtitle>
            <div className={cn('dynamic-waiver-section')}>{field}</div>
          </Container>
        </Column>
      </Row>
    </Styled>
  );
};
