import React from 'react';
import { Form } from 'connex-cds';
import { Signature } from '../signature-component/Signature';
import { TimeDrawer } from '../time-drawer/TimeDrawer';
import { useMaterialServiceSchema } from './useMaterialServiceSchema';
import { useWaterAddedSchema } from './useWaterAddedSchema';
import { WaterReleaseSignature } from '../signature-component/water-release-signature/WaterReleaseSignature';
import { DamageWaiverSignature } from '../signature-component/damage-waiver-signature/DamageWaiverSignature';
import { DynamicWaiverSignature } from '../signature-component/dynamic-waiver-signature/DynamicWaiverSignature';
import { InspectorSignature } from '../wizard/steps/dot-workflow/Signatures/InspectorSignature';
import { ProducerSignature } from '../wizard/steps/dot-workflow/Signatures/ProducerSignature';

const { FIELD_TYPES } = Form;

export const useFieldsConfig = (loadedQuantity, primaryLineItem = null) => {
  const materialServiceSchema = useMaterialServiceSchema(loadedQuantity);
  const waterAddedSchema = useWaterAddedSchema(loadedQuantity);

  const labelMaxWater = React.useMemo(() => {
    const { maxWaterToAdd } = primaryLineItem?.production ?? {};

    if (maxWaterToAdd?.value) {
      return {
        key: 'quantityMaxWaterToAdd',
        values: { maxWaterQuantity: maxWaterToAdd.value },
      };
    }

    return 'quantity';
  }, [primaryLineItem?.production?.maxWaterToAdd]);

  return [
    {
      path: 'waterAddedEvents',
      name: 'water',
      labelStringId: 'water',
      testId: 'water',
      dataType: FIELD_TYPES.array,
      arrayOf: {
        validationSchema: waterAddedSchema,
        fields: [
          {
            path: 'waterProduct',
            name: 'waterProduct',
            labelStringId: 'waterProduct',
            dataType: Form.FIELD_TYPES.string,
            valuePath: 'productRef',
            displayPath: 'description',
            listValues: [],
            listTypeId: 'product',
            listValuePath: 'crn',
            listDisplayPath: 'name',
            testId: 'waterProduct',
          },
          {
            path: 'reason',
            valuePath: 'reasonRef',
            displayPath: 'description',
            labelStringId: 'reason',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'reason',
          },
          {
            path: 'quantity',
            labelStringId: labelMaxWater,
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['LTR', 'GLL'],
            required: { message: 'required' },
            testId: 'quantity',
          },
          {
            path: 'concreteOnTruck',
            labelStringId: 'materialOnTruck',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['MTQ', 'YDQ'],
            testId: 'concreteOnTruck',
          },
          {
            path: 'time',
            labelStringId: 'time',
            FormComponent: TimeDrawer,
            dataType: FIELD_TYPES.string,
            testId: 'time',
          },
          {
            path: 'driverDidNotAdd',
            labelStringId: 'driverDidNotAdd',
            dataType: FIELD_TYPES.boolean,
            testId: 'driverDidNotAdd',
          },
          {
            path: 'waterProductSource',
            valuePath: 'id',
            displayPath: 'description',
            labelStringId: 'source',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'id',
            listDisplayPath: 'description',
            testId: 'waterProductSource',
          },
          {
            path: 'waterLocation',
            name: 'waterLocation',
            labelStringId: 'waterLocation',
            dataType: Form.FIELD_TYPES.string,
            valuePath: 'id',
            displayPath: 'description',
            listDisplayPath: 'description',
            listValues: [],
            testId: 'waterLocation',
          },
        ],
      },
    },
    {
      name: 'materialService',
      path: 'lineItems',
      labelStringId: 'materialService',
      testId: 'materialService',
      dataType: FIELD_TYPES.array,
      arrayOf: {
        validationSchema: materialServiceSchema,
        fields: [
          {
            path: 'item',
            name: 'product',
            valuePath: 'productRef',
            displayPath: 'description',
            labelStringId: 'materialService',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'name',
            testId: 'product',
          },
          {
            path: 'quantity',
            labelStringId: 'quantity',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['EA', 'MTQ', 'LTR', 'YDQ', 'GLL'],
            testId: 'quantity',
          },
          {
            path: 'concreteOnTruck',
            labelStringId: 'materialOnTruck',
            dataType: FIELD_TYPES.quantity,
            uomListValues: ['MTQ', 'YDQ'],
            testId: 'concreteOnTruck',
          },
          {
            path: 'reason',
            valuePath: 'reasonRef',
            displayPath: 'description',
            labelStringId: 'reason',
            dataType: FIELD_TYPES.string,
            listValues: [],
            listValuePath: 'crn',
            listDisplayPath: 'description',
            testId: 'reason',
          },
          {
            path: 'time',
            labelStringId: 'time',
            FormComponent: TimeDrawer,
            dataType: FIELD_TYPES.string,
            testId: 'time',
          },
          {
            path: 'driverDidNotAdd',
            labelStringId: 'driverDidNotAdd',
            dataType: FIELD_TYPES.boolean,
            testId: 'driverDidNotAdd',
          },
          {
            path: 'unitPrice',
            labelStringId: 'unitPrice',
            dataType: FIELD_TYPES.number,
            testId: 'materialServiceUnitPriceTest',
          },
        ],
      },
    },
    {
      path: 'returnConcreteOnTruck',
      labelStringId: 'remainingMaterial',
      dataType: FIELD_TYPES.quantity,
      uomListValues: ['MTQ', 'YDQ'],
      testId: 'concreteOnTruck',
    },
    {
      path: 'returnReason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'returnReason',
    },
    {
      path: 'returnConcreteSource',
      valuePath: 'id',
      displayPath: 'description',
      labelStringId: 'source',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'description',
      testId: 'returnConcreteSource',
    },
    {
      path: 'driverDidNotAdd',
      labelStringId: 'noRestQuantity',
      dataType: FIELD_TYPES.boolean,
      testId: 'driverDidNotAdd',
    },
    {
      path: 'totalPumpedQuantity',
      labelStringId: 'totalPumpedQuantity',
      dataType: FIELD_TYPES.quantity,
      uomListValues: ['MTQ', 'YDQ'],
      testId: 'totalPumpedQuantity',
    },
    {
      path: 'signature',
      labelStringId: 'reason',
      Component: Signature,
    },
    {
      path: 'signature.signerName',
      name: 'signatureSiteContact',
      labelStringId: 'signatureSiteContact',
      dataType: FIELD_TYPES.string,
      testId: 'signatureSiteContact',
    },
    {
      path: 'comments.driver',
      name: 'driverComment',
      labelStringId: 'driverComment',
      dataType: FIELD_TYPES.string,
      testId: 'driverComment',
      multiline: true,
    },
    {
      path: 'comments.customer',
      name: 'customerComment',
      labelStringId: 'customerComment',
      dataType: FIELD_TYPES.string,
      testId: 'customerComment',
      multiline: true,
    },
    {
      path: 'unloadingMethod',
      valuePath: 'unloadingMethodRef',
      displayPath: 'description',
      labelStringId: 'unloadingMethod',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      initialFocus: true,
      testId: 'unloadingMethod',
    },
    {
      path: 'driverAcceptanceReason',
      name: 'reason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'driverAcceptanceReason',
    },
    {
      path: 'ticketEvents.END_UNLOADING.eventDateTime',
      name: 'endUnloading',
      labelStringId: 'END_UNLOADING',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.ARRIVE_JOB.eventDateTime',
      name: 'arriveJob',
      labelStringId: 'ARRIVE_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PRINTED.eventDateTime',
      name: 'printed',
      labelStringId: 'PRINTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LOADING_STARTED.eventDateTime',
      name: 'loadingStarted',
      labelStringId: 'LOADING_STARTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LOADING_COMPLETE.eventDateTime',
      name: 'loadingComplete',
      labelStringId: 'LOADING_COMPLETE',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.TO_JOB.eventDateTime',
      name: 'toJob',
      labelStringId: 'TO_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.UNLOADING.eventDateTime',
      name: 'unloading',
      labelStringId: 'UNLOADING',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.LEAVE_JOB.eventDateTime',
      name: 'leaveJob',
      labelStringId: 'LEAVE_JOB',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.IN_YARD.eventDateTime',
      name: 'inYard',
      labelStringId: 'IN_YARD',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PUMPING_STARTED.eventDateTime',
      name: 'pumpingStarted',
      labelStringId: 'PUMPING_STARTED',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.PUMPING_COMPLETE.eventDateTime',
      name: 'pumpingComplete',
      labelStringId: 'PUMPING_COMPLETE',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'ticketEvents.READY_TO_PUMP.eventDateTime',
      name: 'readyToPump',
      labelStringId: 'READY_TO_PUMP',
      dataType: FIELD_TYPES.touchTime,
      testId: 'time',
    },
    {
      path: 'finalized',
      labelStringId: 'finalized',
      dataType: FIELD_TYPES.boolean,
      testId: 'finalized',
    },
    {
      path: 'customerRejectReason',
      valuePath: 'reasonRef',
      displayPath: 'description',
      labelStringId: 'reason',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'description',
      testId: 'customerRejectReason',
    },
    {
      path: 'customerStatus',
      labelStringId: 'customerStatus',
      dataType: FIELD_TYPES.string,
      testId: 'customerStatus',
      defaultValue: 'PENDING',
    },
    {
      path: 'termsAndConditionsAccepted',
      labelStringId: 'termsAndConditionsAccepted',
      dataType: FIELD_TYPES.boolean,
      testId: 'termsAndConditionsAccepted',
    },
    {
      path: 'productCompliance.consistenceValue',
      name: 'consistenceValue',
      labelStringId: 'consistenceValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'consistenceValue',
    },
    {
      path: 'productCompliance.consistenceIsCompliant',
      name: 'consistenceIsCompliant',
      labelStringId: 'consistenceIsCompliant',
      dataType: FIELD_TYPES.boolean,
      testId: 'consistenceIsCompliant',
    },
    {
      path: 'productCompliance.airContentValue',
      name: 'airContentValue',
      labelStringId: 'airContentValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'airContentValue',
    },
    {
      path: 'productCompliance.airContentIsCompliant',
      name: 'airContentIsCompliant',
      labelStringId: 'airContentIsCompliant',
      dataType: FIELD_TYPES.boolean,
      testId: 'airContentIsCompliant',
    },
    {
      path: 'productCompliance.dateTest',
      name: 'dateTest',
      labelStringId: 'dispatchTime',
      FormComponent: TimeDrawer,
      dataType: FIELD_TYPES.string,
      value: '',
      testId: 'dateTest',
    },
    {
      path: 'productCompliance.airTempValue',
      name: 'airTempValue',
      labelStringId: 'airTempValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'airTempValue',
    },
    {
      path: 'productCompliance.concreteTempValue',
      name: 'concreteTempValue',
      labelStringId: 'concreteTempValue',
      dataType: FIELD_TYPES.quantity,
      testId: 'concreteTempValue',
    },
    {
      path: 'productCompliance.cylinderNumber',
      name: 'cylinderNumber',
      labelStringId: 'cylinderNumber',
      dataType: FIELD_TYPES.quantity,
      testId: 'cylinderNumber',
    },
    {
      path: 'productCompliance.additionalInfo',
      name: 'additionalInfo',
      labelStringId: 'additionalInfo',
      dataType: FIELD_TYPES.string,
      testId: 'additionalInfo',
      multiline: true,
    },
    {
      name: 'riskAssessment',
      path: 'riskAssessment',
      labelStringId: 'riskAssessment',
      testId: 'riskAssessment',
      dataType: FIELD_TYPES.array,
      arrayOf: {
        fields: [
          {
            path: 'category',
            labelStringId: 'category',
            testId: 'category',
            dataType: FIELD_TYPES.string,
          },
          {
            path: 'subcategory',
            labelStringId: 'subcategory',
            testId: 'subcategory',
            dataType: FIELD_TYPES.string,
          },
          {
            path: 'passAssessment',
            labelStringId: 'passAssessment',
            dataType: FIELD_TYPES.boolean,
            testId: 'passAssessment',
          },
        ],
      },
    },
    {
      path: 'damageWaiverSignature',
      labelStringId: 'reason',
      Component: DamageWaiverSignature,
    },
    {
      path: 'damageWaiverSignature.signerName',
      name: 'damageWaiverSignatureSiteContact',
      labelStringId: 'signatureSiteContact',
      dataType: FIELD_TYPES.string,
      testId: 'damageWaiverSignatureSiteContact',
    },
    {
      path: 'damageWaiverDeclined',
      labelStringId: 'damageWaiverDeclined',
      dataType: FIELD_TYPES.boolean,
      testId: 'damageWaiverDeclined',
    },
    {
      path: 'dynamicWaiverSignature',
      labelStringId: 'reason',
      Component: DynamicWaiverSignature,
    },
    {
      path: 'dynamicWaiverSignature.signerName',
      name: 'dynamicWaiverSignatureSiteContact',
      labelStringId: 'signatureSiteContact',
      dataType: FIELD_TYPES.string,
      testId: 'dynamicWaiverSignatureSiteContact',
    },
    {
      path: 'dynamicWaiverDeclined',
      labelStringId: 'dynamicWaiverDeclined',
      dataType: FIELD_TYPES.boolean,
      testId: 'dynamicWaiverDeclined',
    },
    {
      path: 'waterReleaseSignature',
      labelStringId: 'reason',
      Component: WaterReleaseSignature,
    },
    {
      path: 'waterReleaseSignature.signerName',
      name: 'waterReleaseSignatureSiteContact',
      labelStringId: 'signatureSiteContact',
      dataType: FIELD_TYPES.string,
      testId: 'waterReleaseSignatureSiteContact',
    },
    {
      path: 'waterReleaseDeclined',
      labelStringId: 'waterReleaseDeclined',
      dataType: FIELD_TYPES.boolean,
      testId: 'waterReleaseDeclined',
    },
    {
      path: 'isCertificationLost',
      labelStringId: 'isCertificationLost',
      dataType: FIELD_TYPES.boolean,
      testId: 'isCertificationLost',
    },
    {
      path: 'enterRecipients',
      name: 'enterRecipients',
      labelStringId: '',
      dataType: FIELD_TYPES.string,
      testId: 'enterRecipients',
      multiline: true,
    },
    {
      path: 'hasSentRecipients',
      name: 'hasSentRecipients',
      labelStringId: '',
      dataType: FIELD_TYPES.boolean,
      testId: 'hasSentRecipients',
    },
    {
      path: 'paymentAmount',
      labelStringId: 'paymentAmount',
      dataType: FIELD_TYPES.quantity,
      testId: 'paymentAmount',
    },
    {
      path: 'paymentAdditionalInfo',
      labelStringId: 'paymentAdditionalInfo',
      dataType: FIELD_TYPES.string,
      testId: 'paymentAdditionalInfo',
      multiline: true,
    },
    {
      path: 'isExpectedPaymentMethod',
      labelStringId: 'isExpectedPaymentMethod',
      dataType: FIELD_TYPES.boolean,
      testId: 'isExpectedPaymentMethod',
    },
    {
      path: 'paymentMethodRef',
      labelStringId: 'paymentMethodRef',
      dataType: FIELD_TYPES.string,
      testId: 'paymentMethodRef',
    },
    {
      path: 'inspectorSignature',
      labelStringId: 'inspectorSignature',
      Component: InspectorSignature,
    },
    {
      path: 'producerSignature',
      labelStringId: 'producerSignature',
      Component: ProducerSignature,
    },
    {
      path: 'inspectorSignature.signerName',
      name: 'inspectorSignatureContact',
      labelStringId: 'inspectorSignatureContact',
      dataType: FIELD_TYPES.string,
      testId: 'inspectorSignatureContact',
    },
    {
      path: 'producerSignature.signerName',
      name: 'producerSignatureContact',
      labelStringId: 'producerSignatureContact',
      dataType: FIELD_TYPES.string,
      testId: 'producerSignatureContact',
    },
  ];
};
