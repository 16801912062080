import cn from 'classnames';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { MaterialIcon } from '../../../components/icons';
import { Translate } from '../../../components/localization';
import SearchFilter, { getFilteredDataByProperties } from '../../../components/form/search-filter';
import Box from '@mui/material/Box';
import { sortAndShiftHomePage } from './util';

const SORT_VALUES = [
  { iconName: 'SortByAlpha', sortKey: 'alpha' },
  { iconName: 'FormatListNumbered', sortKey: 'default' },
];

const SubMenuContent = ({
  activatedSubMenu,
  isMobile,
  clearActivatedSubMenu,
  enableSearch,
  toggleSubMenuOpen,
  amIActive,
  handleMenuItemClick,
  toggleSidebar,
}) => {
  const [querySearch, setQuerySearch] = useState({});
  const [hideSortableSubMenu, setHideSortableSubMenu] = useState(true);
  const [sortMode, setSortMode] = useState('default');

  const finalQuerySearch = useMemo(() => {
    return querySearch[activatedSubMenu?.id] || '';
  }, [querySearch, activatedSubMenu?.id]);

  // Sort and shift home page to top
  const sortedChildren = useMemo(() => {
    return sortAndShiftHomePage(activatedSubMenu?.children || [], sortMode);
  }, [activatedSubMenu?.children, sortMode]);

  const filteredMenuOptions = useMemo(() => {
    return getFilteredDataByProperties({ data: sortedChildren, query: finalQuerySearch, properties: ['label'] });
  }, [sortedChildren, finalQuerySearch]);

  useEffect(() => {
    setHideSortableSubMenu(activatedSubMenu?.hideSortControls);
    setSortMode(activatedSubMenu?.sortMode);
  }, [activatedSubMenu]);

  const handleOnSearch = useCallback(
    query => setQuerySearch({ ...querySearch, [activatedSubMenu?.id]: query }),
    [querySearch, activatedSubMenu?.id]
  );

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            'padding-left': '32px',
            'padding-top': '22px',
            gap: '10px',
          }}
          className={cn('menu-option', {
            'is-mobile': isMobile,
          })}
          onClick={() => {
            toggleSubMenuOpen();
            clearActivatedSubMenu();
          }}
        >
          <MaterialIcon iconName={'ArrowBackRounded'} />
          <Box component="span">Main Menu</Box>
        </Box>
      )}

      <div className="menu-title-container">
        {activatedSubMenu?.iconName ? <MaterialIcon iconName={activatedSubMenu?.iconName} /> : activatedSubMenu?.icon}
        <Translate stringId={activatedSubMenu?.labelStringId} />
      </div>
      {!hideSortableSubMenu ? (
        <div className="menu-order-container">
          {SORT_VALUES?.map(sortValue => (
            <div
              key={sortValue.sortKey}
              className={cn('menu-order-icon', { 'menu-order-icon__active': sortMode === sortValue.sortKey })}
              onClick={() => setSortMode(sortValue.sortKey)}
            >
              <MaterialIcon iconName={sortValue?.iconName} fontSize="small" />
            </div>
          ))}
        </div>
      ) : null}
      {enableSearch && (
        <div className="menu-search-container">
          <SearchFilter fullWidth={true} onSearchChange={handleOnSearch} querySearch={finalQuerySearch} />
        </div>
      )}
      <div className="menu-options-container">
        {filteredMenuOptions?.map((subMenuItem, index) => {
          return (
            <div
              key={index + subMenuItem?.labelStringId}
              className={cn('menu-option', { 'menu-option__active': amIActive(subMenuItem), 'is-mobile': isMobile })}
              onClick={() => {
                handleMenuItemClick(subMenuItem.path);
                clearActivatedSubMenu();
                isMobile && toggleSidebar();
              }}
            >
              <Translate stringId={subMenuItem?.labelStringId} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SubMenuContent;
