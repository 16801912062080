import { css } from 'styled-components';

export default css`
  .caption {
    display: flex;
    width: 100%;

    .ticket-number {
      margin-right: 25px;
    }
  }

  .signature-section-container {
    padding: 5px 10px;
  }

  img {
    position: unset !important;
    height: 90px;
  }
`;
