import cn from 'classnames';
import { Drawer, Form, Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { View } from './View';
import { WaterReleaseModal } from './water-release/WaterReleaseModal';

const { DrawerProvider } = Drawer;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .actions {
    margin-top: 20px;
    display: flex;
    //gap: 20px;
    justify-content: flex-end;
  }
`;

// TODO: probable duplication with ../add-products-services.  Refactor...

export const AddWater = props => {
  const {
    Components: { Water, WaterReleaseSignature, WaterReleaseSignatureSiteContact },
    setFieldValue,
    values,
  } = Form.useFormContext();

  const { isLoading } = props;

  const disableWaterReleaseButton = React.useMemo(() => {
    if (values?.waterReleaseDeclined === '' && !values?.waterReleaseSignature?.image) {
      return false;
    }

    if (values?.waterReleaseDeclined === '' && values?.waterReleaseSignature?.image) {
      return false;
    }

    if (values?.waterReleaseDeclined === false && values?.waterReleaseSignature?.image) {
      return true;
    }

    if (values?.waterReleaseDeclined && !values?.waterReleaseSignature?.image) {
      return true;
    }

    return false;
  }, [values?.waterReleaseDeclined, values?.waterReleaseSignature?.image]);

  const handleWaterReleaseReset = () => {
    setFieldValue('waterReleaseDeclined', '');
    setFieldValue('waterReleaseSignature', {
      signerName: '',
    });
  };

  const handleWaterReleaseDeclined = () => {
    setFieldValue('waterReleaseDeclined', true);
    setFieldValue('waterReleaseSignature', undefined);
  };

  const handleWaterReleaseSubmitSignature = () => {
    setFieldValue('waterReleaseDeclined', false);
  };

  const renderWaterReleaseSignature = ticketId => {
    return (
      <WaterReleaseSignature edit ticketId={ticketId} middleFunction={() => false}>
        <WaterReleaseSignatureSiteContact />
      </WaterReleaseSignature>
    );
  };

  const renderWaterReleaseModal = () => {
    return (
      <WaterReleaseModal
        renderWaterReleaseSignature={renderWaterReleaseSignature}
        handleWaterReleaseDeclined={handleWaterReleaseDeclined}
        handleWaterReleaseReset={handleWaterReleaseReset}
        handleWaterReleaseSubmitSignature={handleWaterReleaseSubmitSignature}
      />
    );
  };

  return (
    <Styled className={cn('water')}>
      <Core.Spinner spin={isLoading}>
        <Water>
          <DrawerProvider>
            <View
              renderWaterReleaseModal={renderWaterReleaseModal}
              disableWaterReleaseButton={disableWaterReleaseButton}
              handleWaterReleaseReset={handleWaterReleaseReset}
            />
          </DrawerProvider>
        </Water>
      </Core.Spinner>
    </Styled>
  );
};
