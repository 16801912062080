import React from 'react';
import { Form, Localization } from 'connex-cds';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();
  const translateMessage = Localization.useTranslateMessage();

  if (!values?.returnReason && !values?.driverDidNotAdd) {
    return null;
  }

  const translateReason = (returnConcreteOnTruck, returnReason) => {
    if (returnConcreteOnTruck?.value && returnReason) {
      const key = `SETUP_REASON-CODE_${values?.returnReason?.id}`.replace(/\s/gi, '_').toUpperCase();
      const translation = translateMessage(key);

      if (translation && translation !== key) {
        return translation;
      }

      if (returnReason?.label) {
        return returnReason?.label;
      }

      if (returnReason?.description) {
        return returnReason?.description;
      }
    }

    return '';
  };

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>
        <Localization.Translate stringId="returnedMaterial" data-testid="returnedMaterial-label" />
      </td>
      <td></td>
      <td data-testid={`${testId}-concrete-value`}>
        <Localization.Uom
          uom={
            values?.returnConcreteOnTruck?.value
              ? values.returnConcreteOnTruck
              : { value: '0', uomCode: values?.returnConcreteOnTruck.uomCode }
          }
        />
      </td>
      <td data-testid={`${testId}-reason-value`}>
        {translateReason(values?.returnConcreteOnTruck, values?.returnReason)}
      </td>
    </tr>
  );
};
