import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Localization, Layout, Core } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';
import { StyledSelect, StyledTextarea, mobileStyle } from './style';
import { Products } from '../acceptance/costAndPayments/products/Products';
import { Summary } from '../acceptance/costAndPayments/summary/Summary';
import getProducts from '../../../getProducts';

const { Container, Column, Row } = Layout;
const { useTranslateMessage } = Localization;

const Styled = styled.div`
  ${mobileStyle}
`;

const testId = 'paymentMethodGrid';

export const PaymentMethodMobile = () => {
  const { ticket, paymentMethod } = useTicketContext();
  const { setFieldValue, values } = Form.useFormContext();
  const translateMessage = useTranslateMessage();

  const handleChange = React.useCallback(
    ({ target: { value, name } }) => {
      setFieldValue(name, name === 'isExpectedPaymentMethod' ? value !== 'other' : value);
    },
    [setFieldValue]
  );

  const handleAmountChange = React.useCallback(
    event => {
      setFieldValue('paymentAmount', event);
    },
    [setFieldValue]
  );

  return (
    <Styled className={cn('payment-method-mobile')}>
      <Container className={cn('payment-method-options')}>
        <Row className={cn('payment-method-row')}>
          <Column>
            <StyledSelect
              data-testid={`${testId}-method-select`}
              value={values?.isExpectedPaymentMethod ? paymentMethod?.id : 'other'}
              name="isExpectedPaymentMethod"
              onChange={handleChange}
            >
              <option value="" selected disabled hidden>
                {translateMessage('method')}
              </option>
              <option data-testid={`${testId}-expected-option`} value={paymentMethod?.id}>
                {paymentMethod?.name}
              </option>
              <option data-testid={`${testId}-other-option`} value="other">
                {translateMessage('otherPaymentMethod')}
              </option>
            </StyledSelect>
          </Column>
          <Column>
            <Core.Input
              data-testid={`${testId}-amount-input`}
              name="paymentAmount"
              type="number"
              onChange={handleAmountChange}
              value={values?.paymentAmount}
              placeholder={translateMessage('amount')}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <StyledTextarea
              data-testid={`${testId}-additional-textarea`}
              name="paymentAdditionalInfo"
              value={values?.paymentAdditionalInfo}
              placeholder={translateMessage('typeAdditionalInfo')}
              minRows={10}
              onChange={handleChange}
            ></StyledTextarea>
          </Column>
        </Row>
      </Container>
      <Products productsData={getProducts(ticket?.lineItems, values?.lineItems, false)} values={values}></Products>
      <Summary
        products={getProducts(ticket?.lineItems, values?.lineItems, false)}
        orderPricing={ticket?.priceSummary}
        pricing={values?.pricing}
        paymentAmount={values?.paymentAmount}
        paymentMethod={values?.isExpectedPaymentMethod ? paymentMethod?.id : 'other'}
      />
    </Styled>
  );
};
