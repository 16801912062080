import cn from 'classnames';
import { Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  text-align: center;
  margin-top: 5px;
`;

export const CustomerCommentButton = ({ onClick, values }) => {
  return (
    <Styled className={cn('customer-comment')}>
      <Core.Button
        onClick={onClick}
        data-testid="customer-comment-button"
        stringId="addComment"
        type="primary"
        disabled={values?.realTimeIsLoading}
      />
    </Styled>
  );
};
