import cn from 'classnames';
import { Form, Core, Drawer } from 'connex-cds';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { FLOW } from '../Acceptance';
import style from './style';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { LoadRejection } from '../../../../LoadRejection';
import { useViewport } from '../../../../../../../../util/useViewport';

const Styled = styled.div`
  ${style}
`;
export const FinalizeActionsDesktop = ({
  finalized,
  flow,
  handleAcceptClick,
  handleRejectClick,
  handleClick,
  errors,
}) => {
  const { Components, setFieldValue, values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const dimensions = useViewport();

  const handleCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const onSubmit = React.useCallback(() => {
    openDrawer({
      titleStringId: 'driverRejection',
      component: <LoadRejection />,
      width: Math.min(750, dimensions?.width),
      onClose: handleCancel,
    });
  }, []);

  return finalized ? (
    <Styled className={cn('finalize-actions accept-reject')}>
      <div className="buttons-signature">
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            stringId="accept"
            onClick={handleAcceptClick}
            type="primary"
            data-testid="accept-button"
            disabled={values?.realTimeIsLoading}
          />
        ) : (
          <Core.Button
            stringId="signature"
            onClick={handleAcceptClick}
            type="primary"
            data-testid="accept-button"
            disabled={values?.realTimeIsLoading}
          />
        )}

        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            danger
            stringId="reject"
            onClick={handleRejectClick}
            type="primary"
            data-testid="reject-button"
            disabled={values?.realTimeIsLoading}
          />
        ) : (
          <Components.SubmitButton
            stringId="no-signature"
            data-testid="no-signature-button"
            type="primary"
            danger
            disabledWhenNotDirty={false}
            disabled={!isEmpty(errors) || values?.realTimeIsLoading}
          />
        )}
        {companySetup?.data?.isRejectLoadsEnable && companySetup?.data?.rejectLoadStep === 'returnedAndFinalize' && (
          <Core.Button
            danger
            stringId="reject"
            onClick={onSubmit}
            type="primary"
            data-testid="reject-button"
            disabled={values?.realTimeIsLoading}
          />
        )}
      </div>
    </Styled>
  ) : (
    <Styled className={cn('finalize-actions finalize')}>
      <div>
        <Core.Button
          className="finalizeButton"
          stringId="finalize"
          onClick={handleClick}
          type="primary"
          data-testid="finalize-button"
        />
      </div>
    </Styled>
  );
};
