import { useCallback } from 'react';
import { Form, usePostMessageContext, Localization } from 'connex-cds';
import { findIndex, isEqual } from 'lodash';
import { Logger } from '../../../../util/log/logger';
import { getCachedItem } from '../../../../util/cache';
import { useDataStore } from './datastore/DataStore';
import { useTicketContext } from './TicketContext';
import { useCompanySetup } from './MasterDataProvider';

const CACHE_KEY = 'tickets';

export const useAutomaticTicketSubmission = () => {
  const companySetup = useCompanySetup();
  const { reset, setSubmitted, submitted } = useTicketContext();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();
  const { errors, values, setFieldValue } = Form.useFormContext();
  const translateMessage = Localization.useTranslateMessage();

  const reason = {
    crn: 'autosubmit',
    id: 'autosubmit',
    description: translateMessage('autoSubmit'),
  };

  const saveTicket = useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      Logger.debug(
        `Mobile Ticket -
        frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - saveTicket: ${JSON.stringify(
          response
        )}`
      );
      sendMessage(JSON.stringify({ type: 'submit', ticket, response }));
      return response;
    },
    [sendMessage, submitTicket]
  );

  const isWaitingForSubmission = useCallback(() => {
    const tickets = getCachedItem(CACHE_KEY);
    Logger.debug(
      `Checking  tickets in cache - 
      frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - isWaitingForSubmission: ${JSON.stringify(
        tickets
      )}`
    );
    return findIndex(tickets, { ticketRef: values?.ticketRef }) !== -1;
  }, [values?.ticketRef]);

  const ticketSubmission = useCallback(
    async keepTicketOpenAfterSubmission => {
      try {
        const isReadyToSubmit = [
          !submitted,
          !isWaitingForSubmission(),
          values?.customerStatus === 'ACCEPTED',
          isEqual(values?.driverAcceptanceReason, reason),
        ].every(Boolean);
        if (isReadyToSubmit) {
          setSubmitted(true);
          Logger.debug(
            `Ready to submit ticket with the following values - 
            frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - ticketSubmission: ${JSON.stringify(
              values
            )}`
          );
          const ticket = await saveTicket(values);
          reset(!keepTicketOpenAfterSubmission === true);
          return ticket;
        }
      } catch (error) {
        sendMessage(
          JSON.stringify({
            type: 'debug',
            ticket: values,
            error: error.message,
          })
        );
        Logger.error(
          `Mobile Ticket -  frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - ticketSubmission: ${error.message}`
        );
        throw error;
      }
    },
    [isWaitingForSubmission, setSubmitted, saveTicket, sendMessage, Logger, reset, submitted, values]
  );

  const submitTicketCallback = useCallback(() => {
    const keepTicketOpenAfterSubmission = companySetup?.data?.keepTicketOpenAfterSubmission;
    const waterIndexes = errors?.waterAddedEvents?.reduce((acc, el, pos) => (el !== null ? [...acc, pos] : acc), []);
    const materialIndexes = errors?.lineItems?.reduce((acc, el, pos) => (el !== null ? [...acc, pos] : acc), []);

    waterIndexes?.forEach(idx => setFieldValue(`waterAddedEvents[${idx}].driverDidNotAdd`, true));
    materialIndexes?.forEach(idx => setFieldValue(`lineItems[${idx}].driverDidNotAdd`, true));

    setFieldValue('returnConcreteOnTruck', values.returnConcreteOnTruck || '');
    setFieldValue('returnConcreteSource', values.returnConcreteSource || 'DRIVER');
    setFieldValue('driverAcceptanceReason', reason);

    if (values?.customerStatus !== 'ACCEPTED') setFieldValue('customerStatus', 'ACCEPTED');

    ticketSubmission(keepTicketOpenAfterSubmission);
  }, [errors?.waterAddedEvents, errors?.lineItems, setFieldValue, values]);

  return { submitTicket: submitTicketCallback };
};
