import React from 'react';
import { Form, Responsive } from 'connex-cds';
import { find, get } from 'lodash';
import { useDoOnce } from '../../../../../../../util/useDoOnce';
import { useTicketContext } from '../../../TicketContext';
import { ListDesktop } from './desktop/ListDesktop';
import { ListMobile } from './mobile/ListMobile';
import { useCompanySetup, useProductSetup } from '../../../MasterDataProvider';

export const CustomListComponent = props => {
  const {
    deleteItem,
    resetForm,
    parentFormContext: { errors },
    mode,
  } = Form.useFormContext();

  const { ticket } = useTicketContext();
  const companySetup = useCompanySetup();
  const productSetup = useProductSetup();

  const setupItems = React.useMemo(
    () => productSetup?.data?.filter?.(product => product.status === 'ACTIVE'),
    [productSetup?.data]
  );

  const isSlumpRackWaterAtPlant = React.useMemo(
    () => companySetup?.data?.isSlumpRackWaterAtPlant ?? false,
    [companySetup?.data?.isSlumpRackWaterAtPlant]
  );

  const stepsValidation = React.useMemo(
    () =>
      isSlumpRackWaterAtPlant
        ? ['LOADING_COMPLETE', 'TO_JOB', 'ARRIVE_JOB', 'UNLOADING', 'END_UNLOADING', 'PUMPING_COMPLETE']
        : ['END_UNLOADING', 'PUMPING_COMPLETE'],
    [isSlumpRackWaterAtPlant]
  );

  const handleDeleteClick = React.useCallback(
    row => {
      const isWaterProduct = Boolean(row?.waterProduct);
      const productItem = find(setupItems, { crn: row?.item?.productRef });
      const isDriverSellable = get(productItem, 'isDriverSellable', false);

      if (isWaterProduct || isDriverSellable) {
        deleteItem(row);
        resetForm({ reinitialize: true });
      }
    },
    [deleteItem, resetForm, setupItems]
  );

  const handleRowClick = React.useCallback(
    row => {
      const isWaterProduct = Boolean(row?.waterProduct);
      const productItem = find(setupItems, { crn: row?.item?.productRef });
      const isDriverSellable = get(productItem, 'isDriverSellable', false);
      const suggestedToDriver = get(row, 'suggestedToDriver', false);
      const ticketEventsExist = ticket?.ticketEvents && stepsValidation.some(event => ticket?.ticketEvents[event]);

      if ((ticketEventsExist || !suggestedToDriver) && (isDriverSellable || isWaterProduct)) {
        props.onRowClick?.(row);
      }
    },
    [props, ticket?.ticketEvents, stepsValidation, setupItems]
  );

  useDoOnce(() => {
    if (props.autoEdit && errors[props.paths.errors] && mode !== 'edit' && props?.data?.[0]) {
      // Have to wait a tick in order for things to be ready.
      setTimeout(() => {
        props.onRowClick?.(props.data[0]);
      });
    }
  });

  const disabled = React.useCallback(
    row => {
      const ticketEventsExist = !Object.keys(ticket?.ticketEvents ?? {}).some(event => stepsValidation.includes(event));
      const productItem = find(setupItems, { crn: row?.item?.productRef });
      const isWaterProduct = Boolean(row?.waterProduct);
      const suggestedToDriver = get(row, 'suggestedToDriver', false);
      const isDriverSellable = get(productItem, 'isDriverSellable', false);

      if (!isDriverSellable) {
        return !isWaterProduct || (ticketEventsExist && suggestedToDriver);
      }

      return ticketEventsExist && suggestedToDriver;
    },
    [ticket?.ticketEvents, stepsValidation, setupItems]
  );

  const listProps = React.useMemo(() => {
    return {
      ...props,
      handleDeleteClick,
      handleRowClick,
      disabled,
      errors,
    };
  }, [disabled, errors, handleDeleteClick, handleRowClick, props]);

  return (
    <Responsive>
      <ListMobile {...listProps} />
      <ListDesktop {...listProps} />
    </Responsive>
  );
};
