import cn from 'classnames';
import { Drawer, Form, Layout, Localization } from 'connex-cds';
import { find, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  useCompanySetup,
  useProductSetup,
  useReasonCodeSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import style from './form-style';

const Styled = styled.div`
  ${style}
`;

const { Row, Container } = Layout;

const sources = [{ id: 'PROBE' }, { id: 'DRIVER' }];

export const FormSection = ({ className }) => {
  const {
    Components: {
      Reason,
      Quantity,
      ConcreteOnTruck,
      Time,
      WaterProduct,
      CancelButton,
      SaveButton,
      ResetButton,
      DriverDidNotAdd,
      WaterProductSource,
      WaterLocation,
    },
    values,
    setFieldValue,
    mode,
    errors,
  } = Form.useFormContext();

  const { translateObjects } = Localization.useTranslateObjects();

  const { closeDrawer } = Drawer.useDrawerContext();
  const [productUom, setProductUom] = React.useState();
  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();

  React.useEffect(() => {
    if (!values?.waterProduct || values?.waterProduct?.mobileTicket?.isReasonFieldEnabled === false) {
      setFieldValue('reason', undefined);
    }
    if (!values?.waterProduct || values?.waterProduct?.mobileTicket?.isConcreteOnTruckFieldEnabled === false) {
      setFieldValue('concreteOnTruck', undefined);
    }
  }, [setFieldValue, values?.waterProduct]);

  const { ticket } = useTicketContext();

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const productSetup = useProductSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const busy = React.useMemo(() => {
    return companySetup.isLoading || vehicleSetup.isLoading || reasonCodeSetup.isLoading || productSetup.isLoading;
  }, [companySetup.isLoading, productSetup.isLoading, reasonCodeSetup.isLoading, vehicleSetup.isLoading]);

  React.useEffect(() => {
    setProductUom(values?.waterProduct?.uomCode);
  }, [values?.waterProduct]);

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    const targetVehicleType = find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
    return targetVehicleType?.id;
  }, [ticket?.vehicleId, vehicleSetup.data, vehicleTypeSetup.data]);

  const reasonsOptions = React.useMemo(() => {
    if (!reasonCodeSetup?.data) {
      return [];
    }

    const reasons = reasonCodeSetup?.data
      ?.filter?.(d => d.type === 'WATER')
      ?.filter?.(reason => reason.status === 'ACTIVE')
      ?.filter?.(reason =>
        companySetup?.data?.isMulticountry === true
          ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
          : reason
      )
      ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length);

    const reasonCodes = translateObjects(reasons, {
      getStringId: x => `SETUP_REASON-CODE_${x}`.replace(/\s/gi, '_').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'description',
    });

    if (!reasonCodes) {
      return reasons;
    }

    return reasonCodes.map(reason => ({ ...reason, description: reason?.label ?? reason?.description }));
  }, [
    companySetup?.data?.isMulticountry,
    reasonCodeSetup?.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    translateObjects,
  ]);

  const waterOptions = React.useMemo(() => {
    const waterProductType = companySetup?.data?.waterProductType?.id;
    if (waterProductType) {
      if (!productSetup?.data) {
        return [];
      }

      const products = productSetup?.data
        ?.filter?.(d => d.type === waterProductType)
        ?.filter?.(product => product.status === 'ACTIVE')
        ?.filter?.(product =>
          companySetup?.data?.isMulticountry === true
            ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
            : product
        )
        ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length);

      const waterProducts = translateObjects(products, {
        getStringId: x => `SETUP_PRODUCT_${x}`.replace(/\s/gi, '_').toUpperCase(),
        getPath: 'id',
        setPath: 'label',
        defaultMessagePath: 'name',
      });

      if (!waterProducts) {
        return products;
      }

      return waterProducts.map(product => ({
        ...product,
        name: product?.label ?? product?.name ?? product?.description,
      }));
    }
    return [];
  }, [
    companySetup?.data?.isMulticountry,
    companySetup?.data?.waterProductType?.id,
    productSetup?.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    translateObjects,
  ]);

  React.useEffect(() => {
    const defaultWaterRef = companySetup?.data?.defaultWaterProduct?.productRef;
    const defaultWater = find(productSetup?.data, { crn: defaultWaterRef });
    if (!values?.waterProduct?.productRef && defaultWater) {
      setFieldValue('waterProduct', { ...omit(defaultWater, 'crn'), productRef: defaultWater.crn });
    }
  }, [
    companySetup?.data?.defaultWaterProduct?.productRef,
    productSetup?.data,
    setFieldValue,
    values?.waterProduct,
    waterOptions,
  ]);

  React.useEffect(() => {
    setConcreteOnTruckUom(primaryLineItem?.quantity?.uomCode);
  }, [primaryLineItem?.quantity?.uomCode]);

  const mobileTicketConfig = React.useMemo(() => {
    const targetItem = find(productSetup?.data, { crn: values?.waterProduct?.productRef });
    return targetItem?.mobileTicket;
  }, [productSetup?.data, values?.waterProduct?.productRef]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);

  const productDisabled = React.useMemo(() => {
    return values?.suggestedToDriver || values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd, values?.suggestedToDriver]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      setFieldValue('quantity', undefined);
      setFieldValue('reason', undefined);
      setFieldValue('concreteOnTruck', undefined);
      setFieldValue('time', undefined);
      setFieldValue('waterLocation', undefined);
    }
  }, [setFieldValue, ticket?.lineItems, values?.driverDidNotAdd, values?.item?.productRef]);

  React.useEffect(() => {
    if (values?.waterProduct?.mobileTicket?.isReasonFieldEnabled && reasonsOptions?.length === 1) {
      const [firstReason] = reasonsOptions;
      const { crn, ...firstReasonProps } = firstReason;
      setFieldValue('reason', { ...firstReasonProps, reasonRef: crn });
    }
  }, [reasonsOptions, values?.waterProduct, setFieldValue]);

  const defaultReason = React.useMemo(() => {
    if (reasonsOptions.some(opt => opt.isDefault)) {
      return reasonsOptions.filter(opt => opt.isDefault);
    }
    return null;
  }, [reasonsOptions]);

  React.useEffect(() => {
    if (defaultReason && !values?.reason) {
      const [option] = defaultReason;
      const { crn, ...defaultReasonProps } = option;
      setFieldValue('reason', { ...defaultReasonProps, reasonRef: crn });
    }
  }, [defaultReason, setFieldValue, values?.reason]);

  React.useEffect(() => {
    if (ticket?.waterAddedEvents?.length && Object.keys(errors).length) {
      const [firstEvent] = ticket?.waterAddedEvents;
      setFieldValue('quantity', firstEvent?.quantity);
    }
  }, [setFieldValue, ticket?.waterAddedEvents, values, errors]);

  const showSource = React.useMemo(
    () => companySetup?.data?.isWaterSourceDcm || companySetup?.data?.isWaterSourceProbe,
    [companySetup?.data?.isWaterSourceDcm, companySetup?.data?.isWaterSourceProbe]
  );

  const sourceOptions = React.useMemo(() => {
    return translateObjects(sources, {
      getPath: 'id',
      setPath: 'description',
    });
  }, [translateObjects]);

  React.useEffect(() => {
    if (ticket?.waterAddedEvents && ticket?.waterAddedEvents.length) {
      const [firstEvent] = ticket?.waterAddedEvents;
      setFieldValue('waterProductSource', { id: firstEvent?.source });
    } else {
      setFieldValue('waterProductSource', { id: 'DRIVER' });
    }
  }, [setFieldValue, ticket?.waterAddedEvents]);

  React.useEffect(() => {
    if (ticket?.waterAddedEvents && ticket?.waterAddedEvents.length) {
      const [firstEvent] = ticket?.waterAddedEvents;
      if (firstEvent?.quantity?.value !== values?.quantity?.value) {
        setFieldValue('waterProductSource', { id: 'DRIVER' });
      } else {
        setFieldValue('waterProductSource', { id: firstEvent?.source });
      }
    }
  }, [setFieldValue, ticket?.waterAddedEvents, values?.quantity]);

  React.useEffect(() => {
    if (mobileTicketConfig?.isTimeFieldEnabled && !values?.driverDidNotAdd && !values?.time) {
      const now = new Date();
      setFieldValue('time', now.toISOString());
    }
  }, [mobileTicketConfig?.isTimeFieldEnabled, values?.driverDidNotAdd, values?.time, setFieldValue]);

  const locationsSource = React.useMemo(() => {
    return [
      { id: 'at-job', description: 'At Job' },
      { id: 'at-home', description: 'At Home' },
    ];
  });

  React.useEffect(() => {
    if (values.driverDidNotAdd && companySetup?.data?.isWaterMandatory) {
      setFieldValue('quantity.value', '0');
    }
  }, [values.driverDidNotAdd, setFieldValue, companySetup?.data?.isWaterMandatory]);

  return (
    <Styled className={cn('form-section add-water-form-section', className)}>
      <Container flex={1}>
        <div className="form">
          <div className="row-1">
            <WaterProduct options={waterOptions} showSearch={false} disabled={productDisabled} />
            {mobileTicketConfig?.isReasonFieldEnabled && (
              <Reason options={reasonsOptions} busy={busy} showSearch={false} disabled={disabled} />
            )}
          </div>
          <div className={cn('row-2', { disabled })}>
            <div className="left">
              <Quantity uomCode={productUom} disabled={disabled} />
              {mobileTicketConfig?.isConcreteOnTruckFieldEnabled && (
                <ConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
              )}
            </div>
            {mobileTicketConfig?.isTimeFieldEnabled && <Time disabled={disabled} />}
          </div>
          {showSource && (
            <div className="row-3">
              <div className="letf" />
              <div className="right">
                <WaterProductSource options={sourceOptions} disabled={true} />
              </div>
            </div>
          )}
          {mode === 'edit' && (
            <div className="row-3">
              <DriverDidNotAdd />
            </div>
          )}
          {companySetup?.data?.isDotEnabled && (
            <div>
              <WaterLocation options={locationsSource} showSearch={false} disabled={disabled} />
            </div>
          )}
        </div>
        <div className="actions">
          <div className="left">
            <ResetButton />
          </div>
          <div className="right">
            <Row>
              <CancelButton onCancel={closeDrawer} />
              <SaveButton onDone={closeDrawer} />
            </Row>
          </div>
        </div>
      </Container>
    </Styled>
  );
};
