import { http } from 'connex-cds';
import { Logger } from '../../util/log/logger';

export default {
  getCurrentTicket: ({ entityRef, truckNumber }) =>
    http.get({ apiName: 'mt', path: `/${entityRef}/vehicles/${truckNumber}/assignment` }),
  getTicket: ({ entityRef, ticketRef }) => http.get({ apiName: 'mt', path: `/${entityRef}/tickets/${ticketRef}` }),
  createTestTicket: ({ entityRef, ticket }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/tickets`, data: ticket }),
  updateTicket: ({ entityRef, ticket }) => {
    Logger.debug(`Mobile Ticket - frontend/src/api/mobile-ticket/index.js: ${JSON.stringify(ticket)}`);
    return http.patch({ apiName: 'mt', path: `/${entityRef}/tickets/${ticket?.ticketRef}`, data: ticket });
  },
  getUnloadingMethods: entityRef => http.get({ apiName: 'mt', path: `/${entityRef}/master-data/unloading-method` }),
  getAppEntities: ({ profileRef, queryParams = {} }) =>
    http.get({ apiName: 'mt', path: `/entities/apps/${profileRef}`, queryParams }),
  getMtTemplatesExternal: ({ profileRef, entityRef }) =>
    http.get({ apiName: 'mt', path: `/${profileRef}/master-data-external/${entityRef}/templates-mt-external` }),
  getCocSetup: ({ entityRef, ticketRef }) => http.get({ apiName: 'mt', path: `/${entityRef}/coc-setup/${ticketRef}` }),
};
