import menuLogo from '../../../assets/menu-logo.svg';
import { ReactComponent as SteeringWheelLogo } from '../../../assets/steering-wheel.svg';
import { ReactComponent as TruckLogo } from '../../../assets/driver-truck.svg';

export default {
  menuLogo,
  sidebarOptions: [
    {
      id: 'haulers',
      labelStringId: 'haulers',
      path: '/haulers',
      permissions: ['*', 'haulers'],
      icon: <TruckLogo />,
    },
    {
      id: 'users',
      labelStringId: 'users',
      path: '/users',
      iconName: 'PersonOutlineOutlined',
      permissions: ['*', 'users'],
      defaultFeature: true,
    },
    {
      id: 'setup',
      labelStringId: 'setup',
      path: '/setup',
      permissions: ['platform-admin'],
      iconName: 'BuildOutlined',
    },
    {
      id: 'driver',
      labelStringId: 'driverExperience',
      path: '/driver',
      disabled: false,
      permissions: ['*', 'driver-exp'],
      icon: <SteeringWheelLogo />,
    },
  ],
};
