import React from 'react';
import cn from 'classnames';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import { TotalPumpedQuantity } from './TotalPumpedQuantity';
import { WaterAdd } from './WaterAdd';
import { WaterRelease } from './WaterRelease';
import { DamageWaiverAck } from './DamageWaiverAck';

export const ActivityMobile = ({ lineItems, includeProductsServices }) => (
  <div className={cn('activity-detail')}>
    <DamageWaiverAck />
    <WaterAdd />
    <WaterRelease />
    {includeProductsServices && <ProductsServices lineItems={lineItems} />}
    <ReturnedMaterial />
    <TotalPumpedQuantity />
  </div>
);
