import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 100%;

  &.wizard {
    .header-wizard {
      margin: 1rem 1rem 0;

      @media (max-width: 950px) {
        box-shadow: var(--boxShadow-container);
      }
    }

    .step-component {
      margin: 1rem 1rem;
    }

    .env {
      margin-left: 5px;
      color: #cccccc;
      font-size: 0.8em;

      @media (max-width: 950px) {
        position: fixed;
        bottom: 0;
      }

      @media (min-width: 951px) {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 950px) {
    height: inherit;
    flex: 1;
    padding-top: 0;
  }

  & > .container {
    padding-bottom: 20px;
  }

  &.finalized {
    padding-top: 0;
  }

  &.form-section {
    &.add-products-form-section {
      & > .container {
        & > .row {
          gap: 0;

          & > * {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
        .actions {
          display: flex;
          margin-top: 0;

          .left {
            flex: 1;
          }

          .left,
          .right {
            & > * {
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .row-2 {
      display: flex;

      & > * {
        &:first-child {
          flex: 1;
        }

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
`;
