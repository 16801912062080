import iconCompanyAdmin from '../../../../assets/app-icons/company-admin.svg';
import iconDispatch from '../../../../assets/app-icons/dispatch-exp.svg';
import iconBulkMaterialsExp from '../../../../assets/app-icons/bulk-materials-exp.svg';
import iconTrackitExp from '../../../../assets/app-icons/trackit-exp.svg';
import iconLoadout from '../../../../assets/app-icons/loadout.svg';
import iconAutomation from '../../../../assets/app-icons/automation.svg';
import iconDrum from '../../../../assets/app-icons/drum.svg';
import iconQc from '../../../../assets/app-icons/qc.svg';
import iconBatchExp from '../../../../assets/app-icons/batch-exp.svg';
import iconAssurance from '../../../../assets/app-icons/assurance.svg';
import iconSupplyExp from '../../../../assets/app-icons/supply-exp.svg';
import iconApis from '../../../../assets/app-icons/apis.svg';
import iconPayments from '../../../../assets/app-icons/payments.svg';
import iconConsumerExp from '../../../../assets/app-icons/consumer-exp.svg';
import iconSalesQuoteExp from '../../../../assets/app-icons/sales-quote-exp.svg';
import iconArExp from '../../../../assets/app-icons/ar-exp.svg';
import iconDefault from '../../../../assets/app-icons/default.svg';

export const appRefToIconMap = {
  'company-admin': iconCompanyAdmin,
  'dispatch-exp': iconDispatch,
  'bulk-materials-exp': iconBulkMaterialsExp,
  'trackit-exp': iconTrackitExp,
  loadout: iconLoadout,
  automation: iconAutomation,
  drum: iconDrum,
  qc: iconQc,
  'batch-exp': iconBatchExp,
  assurance: iconAssurance,
  'supply-exp': iconSupplyExp,
  apis: iconApis,
  payments: iconPayments,
  'consumer-exp': iconConsumerExp,
  'sales-quote-exp': iconSalesQuoteExp,
  'ar-exp': iconArExp,
};

export const categoryOrderMap = {
  0: ['company-admin'],
  1: ['dispatch-exp'],
  2: ['bulk-materials-exp', 'trackit-exp'],
  3: ['loadout', 'automation', 'drum', 'qc', 'batch-exp', 'assurance', 'supply-exp'],
  4: ['apis', 'payments', 'consumer-exp', 'sales-quote-exp', 'ar-exp'],
};

export default iconDefault;
