import React from 'react';
import { Localization } from 'connex-cds';
import { Styled } from './style';

const testId = 'activityGrid-productSvc';

export const ProductsServices = ({ lineItems }) => {
  const translateMessage = Localization.useTranslateMessage();

  if (!lineItems?.length) {
    return null;
  }

  return (
    <Styled className="product-services-mobile">
      {lineItems?.map?.((materialService, index) => {
        const key = `SETUP_REASON-CODE_${materialService?.reason?.id}`.replace(/\s/gi, '_').toUpperCase();
        const translation = translateMessage(key);

        return (
          <table>
            <tr className="header-row">
              <td className="header-cell full" colSpan="2" data-testid={`${testId}-row-${index}-activity-value`}>
                {materialService?.label}
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="quantity" data-testid="quantity-label" />
              </td>
              <td data-testid={`${testId}-row-${index}-quantity-value`}>
                <Localization.Uom uom={materialService?.quantity} />
              </td>
            </tr>
            <tr>
              <td className="header-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Translate stringId="materialOnTruck" data-testid="water-added-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Uom uom={materialService?.concreteOnTruck} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="reason" data-testid="reason-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-reason-value`}>
                {translation && translation !== key ? translation : materialService?.reason?.description}
              </td>
            </tr>
          </table>
        );
      })}
    </Styled>
  );
};
