import { css } from 'styled-components';

export default css`
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 950px) {
    .subtitle {
      width: max-content;
    }

    .materialCompliance-section {
      width: max-content;
    }
  }

  .materialCompliance-section {
    padding: 0px 5px;
  }

  .comment-date-time {
    font-weight: bold;
    display: flex;

    & > *:first-child {
      flex: 1;
    }
  }

  .actions {
    margin: auto;
    margin-top: 10px;
  }
`;
