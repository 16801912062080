import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Save',
  username: 'Username',
  password: 'Password',
  login: 'Login',
  required: 'Required',
  light: 'Light',
  dark: 'Dark',
  rememberMe: 'Remember Me',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacy Policy',
  customer: 'Customer',
  project: 'Project',
  isBillable: 'Billable',
  dispatchDateTime: 'Date',
  orderId: 'Order',
  ticketId: 'Ticket',
  location: 'Location',
  vehicleTypeName: 'Vehicle',
  product: 'Product',
  products: 'Product',
  salesTax: 'Sales Tax',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destination',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Time',
  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',
  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,
  columnFilters: 'Column Filters',
  visible: 'Visible',
  hidden: 'Hidden',
  itemDetail: 'Item Detail',
  quantity: 'Quantity',
  quantityMaxWaterToAdd: 'Quantity (Max Water to Add: {maxWaterQuantity})',
  unloadingMethod: 'Unloading Method',
  time: 'Time',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Cubic Meters',
  M3_text: 'Cubic Meters',
  EA_text: 'Each',
  LTR_text: 'Liters',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimeter',
  CMT_text: 'Centimeter',
  MTR_text: 'Meter',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometer',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimeter',
  MTK_text: 'Square Meter',
  KMK_text: 'Square Kilometer',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimeter',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimeter',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'Pounds Per Square Inch',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimeter',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centiliter',
  DLT_text: 'Deciliter',
  GLI_text: 'Imperial Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',
  MMK_text: 'Square Millimeter',
  MTN_text: 'Metric Ton',
  GT_text: 'Gigaton',
  '/NL_text': 'Per Load',
  '/EA_text': 'Per Quantity',
  '/H/ + h_text': 'Per Hour',
  '/YQ/ + YDQ_text': 'Per Cubic Yard',
  '/MQ/ + MTQ_text': 'Per Cubic Meter',
  '/MN/ + MIN_text': 'Per Minute',
  '/MT_text': 'Per Meter',
  DSG_text: 'Dosage',
  '/C_text': 'Per 100 Weight',
  '64_2_text': 'Specific',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeters}
    one {1 Millimeter}
    other {# Millimeters}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimeters}
    one {1 Centimeter}
    other {# Centimeters}
  }`,
  MTR: `{value, plural,
    =0 {0 Meters}
    one {1 Meter}
    other {# Meters}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometers}
    one {1 Kilometer}
    other {# Kilometers}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimeters}
    one {1 Square Centimeter}
    other {# Square Centimeters}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometers}
    one {1 Square Kilometer}
    other {# Square Kilometers}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimeters}
    one {1 Cubic Millimeter}
    other {# Cubic Millimeters}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimeters}
    one {1 Cubic Centimeter}
    other {# Cubic Centimeters}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimeter}
    one {1 Newton Per Square Millimeter}
    other {# Newtons Per Square Millimeter}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    one {1 Percent}
    other {# Percent}
  }`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    one {1 Degree Celsius}
    other {# Degrees Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    one {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    one {1 Barrel}
    other {# Barrels}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    one {1 Centilitre}
    other {# Centilitres}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    one {1 Decilitre}
    other {# Decilitres}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    one {1 Megagram}
    other {# Megagrams}
  }`,
  SA: `{value, plural,
    =0 {0 Sacks}
    one {1 Sack}
    other {# Sacks}
  }`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    one {1 Percent Weight}
    other {# Percent Weight}
  }`,
  BG: `{value, plural,
    =0 {0 Bags}
    one {1 Bag}
    other {# Bags}
  }`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    one {1 Second}
    other {# Seconds}
  }`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    one {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
  }`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    one {1 Shelf Package}
    other {# Shelf Packages}
  }`,
  MMK: `{value, plural,
    =0 {0 Square Millimeters}
    one {1 Square Millimeter}
    other {# Square Millimeters}
  }`,
  MTN: `{value, plural,
    =0 {0 Metric Tons}
    one {1 Metric Ton}
    other {# Metric Tons}
  }`,
  GT: `{value, plural,
    =0 {0 Gigatons}
    one {1 Gigaton}
    other {# Gigatons}
  }`,
  '/NL': `{value, plural,
    =0 {0 Per Load}
    one {1 Per Load}
    other {# Per Load}
  }`,
  '/EA': `{value, plural,
    =0 {0 Per Quantity}
    one {1 Per Quantity}
    other {# Per Quantity}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 Per Hour}
    one {1 Per Hour}
    other {# Per Hour}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 Per Cubic Yard}
    one {1 Per Cubic Yard}
    other {# Per Cubic Yard}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 Per Cubic Meter}
    one {1 Per Cubic Meter}
    other {# Per Cubic Meter}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 Per Minute}
    one {1 Per Minute}
    other {# Per Minute}
  }`,
  '/MT': `{value, plural,
    =0 {0 Per Meter}
    one {1 Per Meter}
    other {# Per Meter}
  }`,
  DSG: `{value, plural,
    =0 {0 Dosage}
    one {1 Dosage}
    other {# Dosage}
  }`,
  '/C': `{value, plural,
    =0 {0 Per 100 Weight}
    one {1 Per 100 Weight}
    other {# Per 100 Weight}
  }`,
  '64_2': `{value, plural,
    =0 {0 Specific}
    one {1 Specific}
    other {# Specific}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
  }`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
  =0 {0 Items}
  one {1 Item}
  other {# Items}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
  =0 {0 Newtons Per Square Millimeter}
  one {1 Newton Per Square Millimeter}
  other {# Newtons Per Square Millimeter}
}`,
  C62_short: `{value, plural,
  =0 {0 One}
  one {1 One}
  other {# One}
}`,
  NL_short: `{value, plural,
  =0 {0 Loads}
  one {1 Load}
  other {# Loads}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
  =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA_short: `{value, plural,
  =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  '60_short': `{value, plural,
  =0 {0 % Weight}
  one {1 % Weight}
  other {# % Weight}
}`,
  BG_short: `{value, plural,
  =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
  =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP_short: `{value, plural,
  =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,
  MMK_short: `{value, plural,
  =0 {0 Square Millimeters}
  one {1 Square Millimeter}
  other {# Square Millimeters}
}`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
  =0 {0 /Load}
  one {1 /Load}
  other {# /Load}
}`,
  '/EA_short': `{value, plural,
  =0 {0 /EA}
  one {1 /EA}
  other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
  =0 {0 /Hour}
  one {1 /Hour}
  other {# /Hour}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
  =0 {0 /CY}
  one {1 /CY}
  other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
  =0 {0 /M3}
  one {1 /M3}
  other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
  =0 {0 /MIN}
  one {1 /MIN}
  other {# /MIN}
}`,
  '/MT_short': `{value, plural,
  =0 {0 /M}
  one {1 /M}
  other {# /M}
}`,
  DSG_short: `{value, plural,
  =0 {0 Dosage}
  one {1 Dosage}
  other {# Dosage}
}`,
  '/C_short': `{value, plural,
  =0 {0 /C}
  one {1 /C}
  other {# /C}
}`,
  '64_2_short': `{value, plural,
  =0 {0 Specific}
  one {1 Specific}
  other {# Specific}
}`,
  mixIdLabel: 'Mix ID/Description',
  orderedQtyLabel: 'Ordered Qty',
  loadedQtyLabel: 'Loaded Qty',
  addedByCustomer: 'Added By Customer',
  PRINTED: 'Ticketed',
  LOADING_STARTED: 'Start Loading',
  LOADING_COMPLETE: 'Finish Loading',
  TO_JOB: 'Left Plant',
  ARRIVE_JOB: 'Arrive Site',
  UNLOADING: 'Start Unload',
  END_UNLOADING: 'Finish Unload',
  LEAVE_JOB: 'Leave Job',
  IN_YARD: 'In Yard',
  PUMPING_STARTED: 'Start Pump',
  PUMPING_COMPLETE: 'Finish Pump',
  READY_TO_PUMP: 'Ready to Pump',
  FIRST_WCCONTACT: 'First Water Cement Contact',
  plantName: 'Plant Name',
  plantAddress: 'Plant Address',
  loadingScheduleStart: 'Scheduled Loading Time',
  deliveryAddress: 'Delivery Address',
  phoneNumber: 'Phone Number',
  unloadingScheduleStart: 'Planned Unloading Time (ETA)',
  agreedUnloading: 'Ordered Unloading Times',
  deliveryInformation: 'Delivery Information',
  next: 'Next',
  material: 'Material',
  loadingPoint: 'Loading Point',
  unloadingPoint: 'Unloading Point',
  comment: 'Comment',
  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',
  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Admix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',
  generalDelivery: 'General Delivery Information',
  materialInfo: 'Material Information',
  water: 'Water',
  materialService: 'Material / Service',
  returnedMaterial: 'Returned Material',
  returnedConcrete: 'Returned Concrete',
  waterAdded: 'Water Added',
  concreteOnTruck: 'Concrete on Truck',
  concreteOnTruckUom: 'Default Concrete on Truck UOM',
  reason: 'Reason',
  customerAddedWater: 'Customer Added Water',
  addWater: 'Add Water',
  addedAt: 'Added at',
  addMaterialService: 'Add Material/Service',
  customerAddedProduct: 'Customer Added Product',
  addReturnedMaterial: 'Add Returned Material',
  leftover: 'Leftover',
  submitAcceptance: 'Submit Acceptance',
  accepted: 'Accepted',
  rejected: 'Rejected',
  customerAcceptance: 'Acceptance',
  finalize: 'Finalize',
  materialSummary: 'Material Summary',
  mix: 'Mix',
  ordered: 'Ordered',
  loaded: 'Delivery Load',
  totalOrdered: 'Total Order Quantity',
  loadedOrderQuantity: 'Loaded Order Quantity',
  loadedOrder: 'Loaded Order',
  driverComment: 'Driver Comment',
  customerComment: 'Customer Comment',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  additionalInfo: 'Additional Info',
  onBoard: 'On Board',
  driver: 'Driver',
  commentInstruction: 'Comment Instruction',
  signatureInstruction: 'Customer Signature',
  customerSignature: 'Customer Signature',
  general: 'General',
  status: 'Status',
  updateStatusTime: 'Update Status Time',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  add: 'Add',
  done: 'Done',
  ok: 'OK',
  decline: 'Decline',
  Submit: 'Submit',
  submit: 'Submit',
  lastRefresh: 'Last Refresh',
  refreshEnabled: 'Enable Refresh',
  id: 'ID',
  name: 'Name',
  invoiceable: 'Invoiceable',
  provideSignature: 'Provide Signature',
  ticket: 'Ticket',
  details: 'Details',
  qrCode: 'QR Code',
  driverAcceptanceReason: 'Reason',
  driverAcceptance: 'Driver Acceptance',
  'payment-method': 'Payment Methods',
  'add-payment-method': 'Add Payment Method',
  'reason-code': 'Reason Code',
  'add-reason-code': 'Add Reason Code',
  'qr-code': 'QR Code',
  'unloading-method': 'Unloading Methods',
  'add-unloading-method': 'Add Unloading Method',
  'driver-comment': 'Driver Comments',
  'add-driver-comment': 'Add Driver Comment',
  'driver-sellable': 'Driver Sellable',
  vehicle: 'Vehicles',
  'add-vehicle': 'Add Vehicle',
  'vehicle-type': 'Vehicle Types',
  vehicleTypes: 'Vehicle Types',
  vehicleType: 'Vehicle Type',
  'add-vehicle-type': 'Add Vehicle Type',
  countries: 'Countries',
  country: 'Country',
  type: 'Type',
  uom: 'UOM',
  mandatory: 'Mandatory',
  qrTemplate: 'QR Code Template',
  includeMixInfo: 'Include Mix Info in QR Code',
  includeTicketEvents: 'Include Ticket Events in QR Code',
  quantityField: 'Enable Quantity Field',
  concreteOnTruckField: 'Enable Concrete On Truck Field',
  materialOnTruckField: 'Enable Material On Truck Field',
  timeField: 'Enable Time Field',
  reasonField: 'Enable Reason Field',
  driverInstructions: 'Driver Instructions',
  waitingForTicket: 'Waiting for ticket...',
  createTestTicketForTruck: 'Create Test Ticket for Truck',
  exceedsLoadedQuantity: 'Value cannot exceed loaded quantity of {loadedQuantity}.',
  maxQuantity: 'Maximum Quantity',
  maxQuantityError: 'Quantity cannot exceed defined maximum quantity of {maxQty}',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',
  notAuthorized: 'Not Authorized',
  enterTruckNumber: 'Enter Truck Number',
  setTruckNumber: 'Set Truck Number',
  commentList: 'Comments List',
  list: 'List',
  gridPreferences: 'Grid Preferences',
  loadingUnloadingPoints: 'Loading/Unloading Points',
  activity: 'Activity',
  crn: 'Crn',
  update: 'Update',
  CONCRETE: 'Concrete',
  WATER: 'Water',
  ADDITIVE: 'Additive',
  'submit-signature-button': 'Accept',
  'cancel-signature-button': 'Cancel',
  uomCode: 'Default UOM',
  'product-type': 'Product Types',
  'add-product-type': 'Add Product Type',
  'add-product': 'Add Product',
  'setup_driver-comment': 'Driver Comments',
  setup_product: 'Products',
  'setup_product-type': 'Product Types',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Reason Codes',
  'setup_unloading-method': 'Unloading Methods',
  'setup_entity-mt': 'Company',
  setup_vehicle: 'Vehicles',
  'setup_vehicle-type': 'Vehicle Types',
  'setup_data-retentions': 'Data Retention',
  'add-data-retentions': 'Add Data Retention',
  'data-retentions': 'Data Retention',
  setup_carriers: 'Carriers',
  'add-carriers': 'Add Carriers',
  carriers: 'Carriers',
  'setup_concrete-classes': 'Material Compliance',
  'add-concrete-classes': 'Add Material Compliance',
  'concrete-classes': 'Material Compliance',
  'setup_forms-and-waivers': 'Forms & Waivers',
  'add-forms-and-waivers': 'Add Forms & Waivers',
  'forms-and-waivers': 'Forms & Waivers',
  companyFleet: 'Company Fleet',
  category: 'Category',
  interval: 'Interval',
  orders: 'Orders And Tickets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',
  waterMandatory: 'Water Mandatory',
  qrCodeTemplate: 'QR Code Template',
  waterConfig: 'Optional Fields',
  waterProduct: 'Water',
  waterProductType: 'Water Product Type',
  defaultWaterProduct: 'Default Water Product',
  invalidTemplate: 'Invalid Template',
  siteAddress: 'Site Address',
  siteContactName: 'Site Contact Name',
  siteContactPhoneNumber: 'Site Contact Phone Number',
  'clear-signature-button': 'Clear Signature',
  customerCommentEnabled: 'Enable Customer Comment',
  driverCommentEnabled: 'Enable Driver Comment',
  driverCommentMaxCharacters: 'Max Length',
  customerCommentMaxCharacters: 'Max Length',
  timeZone: 'Time Zone',
  driverDidNotAdd: 'Driver Did Not Add',
  maxLineItems: 'Max Number of Line Items',
  oneLineItem: 'Must have at least 1 line item',
  signatureSiteContact: 'Customer Name',
  ticketEvents: 'Ticket Events',
  hideEvent: 'Hide Event',
  editable: 'Editable',
  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Status Times',
  materialAndDeliveryInfo: 'Material and Delivery Information',
  customerRejection: 'Customer Rejection',
  'submit-rejection-button': 'Submit Rejection',
  accept: 'Accept',
  reject: 'Reject Load',
  signature: 'Signature',
  'no-signature': 'No Signature',
  unFinalizePassword: 'Un-Finalize Password',
  waterInWorkflow: 'Enable Water Step in Workflow',
  multicountry: 'Multicountry',
  timestampAfterSequence: 'WARNING: Time should not be before previous timestamp',
  timestampBeforeSequence: 'WARNING: Time should not be after next timestamp',
  noRestQuantity: 'No Returned Material',
  returnedMaterialMandatory: 'Must Interact With Returned Material Step',
  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',
  QrCodeEnabled: 'Enable QR Code',
  QrMethod_linkBased: 'Link based',
  QrMethod_contentBased:
    'Content based, NOTE: Content Based QR Codes has character limitations that may cause issues beyond 1024 characters.',
  QrMethod_contentBasedWarning: 'NOTE: This QR code has {amount} characters that may not be readable by some phones.',
  returnedMaterialInWorkflow: 'Enable Returned Material Step in Workflow',
  materialServiceInWorkflow: 'Enable Material/Service Step in Workflow',
  totalPumpedQuantity: 'Total Pumped Quantity',
  concrete: 'Concrete',
  materialComplianceEnabled: 'Enable Material Compliance',
  pumpStatusTimes: 'Pump Status Times',
  pollingInterval: 'Polling Interval (seconds)',
  tenSecs: 'Interval must be at least 10 seconds',
  materialCompliance: 'Material Compliance',
  consistenceValue: 'Consistence - Slump',
  consistenceIsCompliant: 'Consistence complies with BS8500-2',
  airContentValue: 'Air content',
  airContentIsCompliant: 'Air Content complies with BS8500-2',
  consistenceIsCompliantShort: 'Consistence complies',
  airContentIsCompliantShort: 'Air Content complies',
  compliance: 'Material Compliance with BS8500-2',
  termsAndConditionsEnabled: 'Enable Terms and Conditions form',
  termsAndConditions: 'Terms and Conditions',
  damageWaiverEnabled: 'Enable Damage Waiver form',
  damageWaiver: 'Damage Waiver',
  damageWaiverAcknowledged: 'Damage Waiver Acknowledged',
  dynamicWaiver: 'Dynamic Waiver',
  dynamicWaiverEnabled: 'Enable Dynamic Waiver form',
  ticketListEnabled: 'Enable Ticket List',
  clearTicketAfterSubmission: 'Clear Ticket After Submission',
  keepTicketOpenAfterSubmission: 'Keep Ticket Open After Submission',
  displayTicketList: 'Display Ticket List',
  supportedLanguages: 'Supported Languages',
  allow: 'Allow',
  default: 'Default',
  isCertificationLossTriggerForReason: 'Reason for Certification Loss Note',
  isCertificationLossTriggerForProduct: 'Certification Loss Note Product',
  translations: 'Translations',
  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  role: 'Role',
  inviteUser: 'Invite User',
  carrier: 'Carrier',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'User Editor',
  verifyPassword: 'Verify Password',
  verificationCode: 'Verification Code',
  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",
  createDate: 'Create Date',
  customerPo: 'Customer PO Number',
  specification: 'Specification',
  description: 'Description',
  classType: 'Class Type',
  minimumValue: 'Minimum Value',
  maximumValue: 'Maximum Value',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Context',
  inviteLanguage: 'Invite Language',
  truckNumber: 'Truck Number',
  adverseTemperature:
    'Ambient temperature at the time of mixing was such that we cannot guarantee that the concrete will be in the temperature ranges of 5-35oC as specified in EN 206.',
  environmentalNote:
    'This load contains an environmental agent which does not exceed 0.03% by weight of the concrete. Water containing this material for the manufacture of concrete will comply with BS EN 1008.',
  unloadingMethodEnabled: 'Enable Unloading Method',
  warrantyVoided: 'Material Warranty Voided',
  materialWarrantyVoidedEnabled:
    'When Certification Loss Note Product or Product & Reason Code is used, show a Certification Loss Note at time of signature.',
  'setup_templates-mt': 'Templates',
  copyFrom: 'Copy From...',
  copy: 'copy',
  users: 'Users',
  setup: 'Setup',
  driverExperience: 'Driver Experience',
  ticketList: 'Ticket List',
  content: 'Content',
  WARNINGS: 'Warnings',
  TERMS: 'Terms',
  DAMAGE: 'Damage',
  ADDITIONALWAIVER: 'Additional Waiver',
  DYNAMICWAIVER: 'Dynamic Waiver',
  source: 'Source',
  sensorUnable: 'Sensor unable to determine leftover quantity.',
  PROBE: 'Probe',
  DRIVER: 'Driver',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Enable Water Release Form',
  waterRelease: 'Water Release',
  waterReleaseAcknowledged: 'Water Release Acknowledged',
  'assessment-risk': 'Risk Assessment',
  'setup_assessment-risk': 'Risk Assessment',
  'add-assessment-risk': 'Add Risk Assessment',
  enterRecipientsButton: 'Enter Recipient(s)',
  recipientsInstructions: 'Enter Email, separated by a comma',
  enter: 'Enter',
  invalidEmailAddress: 'invalid email address',
  personalDataPolicy:
    'By entering personal data into this form, you affirm that you have the necessary permissions to share that personal data',
  subcategory: 'Subcategory',
  isRequired: 'Is Required',
  riskAssessmentEnabled: 'Enable Risk Assessment',
  riskAssessment: 'Risk Assessment',
  yes: 'Yes',
  no: 'No',
  sourceOptionsSensors: 'Source Options (sensors)',
  sourceDcm: 'DCM',
  sourceProbe: 'Probe',
  availableCustomValues: 'Available Custom Values',
  ERR_CODE__DUPLICATE: 'Duplicate',
  ERR_CODE__DEFAULT_VALUE: 'There is already a default value',
  ERR_CODE__EXISTING_FORM: 'There is already an active form for this Vehicle and Type',
  availableValues: 'Available Values',
  pump: 'Pump',
  DanskName: 'Dansk Name',
  GermanName: 'German Name',
  EnglishName: 'English Name',
  BritainName: 'Britain Name',
  SpanishName: 'Spanish Name',
  FrenchName: 'French Name',
  NederlandsName: 'Nederlands Name',
  NorskName: 'Norsk Name',
  SvenskaName: 'Svenska Name',
  ItalianName: 'Italian Name',
  PolishName: 'Polish Name',
  DanskNameDefault: 'Dansk Name (Default)',
  GermanNameDefault: 'German Name (Default)',
  EnglishNameDefault: 'English Name (Default)',
  BritainNameDefault: 'Britain Name (Default)',
  SpanishNameDefault: 'Spanish Name (Default)',
  FrenchNameDefault: 'French Name (Default)',
  NederlandsNameDefault: 'Nederlands Name (Default)',
  NorskNameDefault: 'Norsk Name (Default)',
  SvenskaNameDefault: 'Svenska Name (Default)',
  ItalianNameDefault: 'Italian Name (Default)',
  PolishNameDefault: 'Polish Name (Default)',
  DanskDescription: 'Dansk Description',
  GermanDescription: 'German Description',
  EnglishDescription: 'English Description',
  BritainDescription: 'Britain Description',
  SpanishDescription: 'Spanish Description',
  FrenchDescription: 'French Description',
  NederlandsDescription: 'Nederlands Description',
  NorskDescription: 'Norsk Description',
  SvenskaDescription: 'Svenska Description',
  ItalianDescription: 'Italian Description',
  PolishDescription: 'Polish Description',
  DanskDescriptionDefault: 'Dansk Description (Default)',
  GermanDescriptionDefault: 'German Description (Default)',
  EnglishDescriptionDefault: 'English Description (Default)',
  BritainDescriptionDefault: 'Britain Description (Default)',
  SpanishDescriptionDefault: 'Spanish Description (Default)',
  FrenchDescriptionDefault: 'French Description (Default)',
  NederlandsDescriptionDefault: 'Nederlands Description (Default)',
  NorskDescriptionDefault: 'Norsk Description (Default)',
  SvenskaDescriptionDefault: 'Svenska Description (Default)',
  ItalianDescriptionDefault: 'Italian Description (Default)',
  PolishDescriptionDefault: 'Polish Description (Default)',
  DanskContent: 'Dansk Content',
  GermanContent: 'German Content',
  EnglishContent: 'English Content',
  BritainContent: 'Britain Content',
  SpanishContent: 'Spanish Content',
  FrenchContent: 'French Content',
  NederlandsContent: 'Nederlands Content',
  NorskContent: 'Norsk Content',
  SvenskaContent: 'Svenska Content',
  ItalianContent: 'Italian Content',
  PolishContent: 'Polish Content',
  DanskContentDefault: 'Dansk Content (Default)',
  GermanContentDefault: 'German Content (Default)',
  EnglishContentDefault: 'English Content (Default)',
  BritainContentDefault: 'Britain Content (Default)',
  SpanishContentDefault: 'Spanish Content (Default)',
  FrenchContentDefault: 'French Content (Default)',
  NederlandsContentDefault: 'Nederlands Content (Default)',
  NorskContentDefault: 'Norsk Content (Default)',
  SvenskaContentDefault: 'Svenska Content (Default)',
  ItalianContentDefault: 'Italian Content (Default)',
  PolishContentDefault: 'Polish Content (Default)',
  DanskCategory: 'Dansk Category',
  GermanCategory: 'German Category',
  EnglishCategory: 'English Category',
  BritainCategory: 'Britain Category',
  SpanishCategory: 'Spanish Category',
  FrenchCategory: 'French Category',
  NederlandsCategory: 'Nederlands Category',
  NorskCategory: 'Norsk Category',
  SvenskaCategory: 'Svenska Category',
  ItalianCategory: 'Italian Category',
  PolishCategory: 'Polish Category',
  DanskCategoryDefault: 'Dansk Category (Default)',
  GermanCategoryDefault: 'German Category (Default)',
  EnglishCategoryDefault: 'English Category (Default)',
  BritainCategoryDefault: 'Britain Category (Default)',
  SpanishCategoryDefault: 'Spanish Category (Default)',
  FrenchCategoryDefault: 'French Category (Default)',
  NederlandsCategoryDefault: 'Nederlands Category (Default)',
  NorskCategoryDefault: 'Norsk Category (Default)',
  SvenskaCategoryDefault: 'Svenska Category (Default)',
  ItalianCategoryDefault: 'Italian Category (Default)',
  PolishCategoryDefault: 'Polish Category (Default)',
  DanskSubcategory: 'Dansk Subcategory',
  GermanSubcategory: 'German Subcategory',
  EnglishSubcategory: 'English Subcategory',
  BritainSubcategory: 'Britain Subcategory',
  SpanishSubcategory: 'Spanish Subcategory',
  FrenchSubcategory: 'French Subcategory',
  NederlandsSubcategory: 'Nederlands Subcategory',
  NorskSubcategory: 'Norsk Subcategory',
  SvenskaSubcategory: 'Svenska Subcategory',
  ItalianSubcategory: 'Italian Subcategory',
  PolishSubcategory: 'Polish Subcategory',
  DanskSubcategoryDefault: 'Dansk Subcategory (Default)',
  GermanSubcategoryDefault: 'German Subcategory (Default)',
  EnglishSubcategoryDefault: 'English Subcategory (Default)',
  BritainSubcategoryDefault: 'Britain Subcategory (Default)',
  SpanishSubcategoryDefault: 'Spanish Subcategory (Default)',
  FrenchSubcategoryDefault: 'French Subcategory (Default)',
  NederlandsSubcategoryDefault: 'Nederlands Subcategory (Default)',
  NorskSubcategoryDefault: 'Norsk Subcategory (Default)',
  SvenskaSubcategoryDefault: 'Svenska Subcategory (Default)',
  ItalianSubcategoryDefault: 'Italian Subcategory (Default)',
  PolishSubcategoryDefault: 'Polish Subcategory (Default)',
  priorSignatures: 'Prior Signatures',
  damageWaiverSignature: 'Damage Waiver Signature',
  waterReleaseSignature: 'Water Release Signature',
  errors,
  themes,
  isPDFRecipientListEnable: 'Enable PDF Recipient List on Customer Signature Screen',
  isRejectLoadsEnable: 'Enable Reject Loads After Arrive Site',
  rejectLoad_returnedMaterial: 'Returned Material Workflow Button',
  rejectLoad_returnedAndFinalize: 'Returned Material And Finalize Workflow Buttons',
  driverRejection: 'Reject Load',
  submitRejection: 'Submit Reject',

  costAndPaymentsSummary: 'Cost and Payments Summary',
  subtotal: 'Subtotal',
  tax: 'Tax',
  remainingBalance: 'Remaining Balance',
  paymentAmount: 'Payment Amount',
  total: 'Total',
  paymentMethod: 'Payment Method',
  paymentProducts: 'Products',
  unitPrice: 'Unit Price',
  amount: 'Amount',
  otherPaymentMethod: 'Other payment method',
  method: 'Method',
  paymentOptions: 'Payment Options',
  typeAdditionalInfo: 'Type additional info here',
  COD: 'Cash on Delivery',
  AMEX: 'American Express',
  CASH: 'Cash',
  CHECK: 'Check',
  'CREDIT CARD': 'Credit Card',
  ACCOUNT: 'Open account',
  UNKNOWN: 'Unknown',
  remainingMaterial: 'Remaining Material',
  splitLoad: 'Split Load - Multiple',
  isEnablePaymentAndCostWorkflows: 'Enable Payment and Cost Workflows',

  // DOT Workflow
  dotWorkflow: 'Batch Details',
  inspectorSignature: 'Inspector Signature',
  producerSignature: 'Producer Signature',
  addedItems: 'Added Items',
  producerSignatureContact: 'Producer Name',
  inspectorSignatureContact: 'Inspector Name',

  //
  airTempValue: 'Air Temperature',
  concreteTempValue: 'Concrete Temperature',
  cylinderNumber: 'Cylinder Number',
  materialOnTruck: 'Material on Truck',
  waterLocation: 'Water Location',
  waterAtPlant: 'Water at plant (gl)',
  waterAtJobsite: 'Water at jobsite (gl)',
  totalActualWater: 'Total Actual Water',
  timeDischarged: 'Time Discharged',
  // news
  ingredient: 'Ingredient',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Free Mst',
  cydsTarg: 'CYDS TARG',
  target: 'Target',
  actual: 'Actual',
  freWat: 'Free Water',
  trim: 'Trim',
  batchInformation: 'Batch Information',
  numberOfBatches: 'Number of batches',
  totalWater: 'Total Water',
  designWater: 'Design Water',
  toAdd: 'To Add',
  designWC: 'Design W/C',
  waterCement: 'Water / Cement',
  waterInTruck: 'Water in Truck',
  adjustWater: 'Adjust Water',
  trimWater: 'Trim Water',
  plantWeather: 'Plant Weather',
  rh: 'RH',
  observationTime: 'Observation Time',
  address: 'Address',
  plant: 'Plant',
  sp: 'S.P. #',
  beginTime: 'Begin Time',
  mixDesc: 'Mix Desc',
  qtyOder: 'QTY Order',
  poTag: 'PO #',
  bridge: 'Bridge #',
  volume: 'Volume',
  truckTag: 'Truck #',
  qtyDelivered: 'QTY Delivered',
  qtyOrder: 'QTY Order',
  job: 'Job #',
  batchRecord: 'Batch Record',
  mixID: 'Mix ID',
  loads: 'Loads',
  isDot: 'Is DOT',
  tolStatus: 'TOL Status',
  lastUpdate: 'Last Update',
  driverClickedFinalize: 'Driver Clicked Finalize',
  autoSubmit: 'Auto Submit',
  noTaxInformationIsAvailable: 'No tax information is available',
  retryPullTaxInformation: 'Refresh Tax Data',
};
