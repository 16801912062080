import cn from 'classnames';
import { Form, Core, Drawer } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { FLOW } from '../Acceptance';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { LoadRejection } from '../../../../LoadRejection';
import { useViewport } from '../../../../../../../../util/useViewport';

const Styled = styled.div`
  padding: 30px;

  .buttons-signature {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  button {
    width: 200px;
    height: 50px;
    font-size: 1.2em;
  }

  &.finalize {
    flex: 1;
    margin-bottom: 20px;

    .actions {
      position: fixed;
      bottom: 31px;
      right: 1rem;
    }
  }

  &.accept-reject {
    margin-left: 60px;
    & > * {
      &:first-child {
        margin-bottom: 20px;

        button {
          background: green;
        }
      }
    }

    @media (max-width: 950px) {
      display: flex;

      & > * {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
`;

export const FinalizeActionsMobile = ({
  finalized,
  flow,
  handleAcceptClick,
  handleRejectClick,
  handleClick,
  errors,
}) => {
  const { Components, setFieldValue, values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();

  const handleCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const onSubmit = React.useCallback(() => {
    openDrawer({
      titleStringId: 'driverRejection',
      component: <LoadRejection />,
      width: '100%',
      onClose: handleCancel,
    });
  }, [handleCancel, openDrawer]);

  return finalized ? (
    <Styled className={cn('finalize-actions accept-reject')}>
      <div className="buttons-signature">
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            stringId="accept"
            onClick={handleAcceptClick}
            type="primary"
            data-testid="accept-button"
            disabled={values?.realTimeIsLoading}
          />
        ) : (
          <Core.Button
            stringId="signature"
            onClick={handleAcceptClick}
            type="primary"
            data-testid="accept-button"
            disabled={values?.realTimeIsLoading}
          />
        )}

        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            danger
            stringId="reject"
            onClick={handleRejectClick}
            type="primary"
            data-testid="reject-button"
            disabled={values?.realTimeIsLoading}
          />
        ) : (
          <Components.SubmitButton
            stringId="no-signature"
            data-testid="no-signature-button"
            type="primary"
            danger
            disabledWhenNotDirty={false}
            disabled={!isEmpty(errors) || values?.realTimeIsLoading}
          />
        )}
        {companySetup?.data?.isRejectLoadsEnable && companySetup?.data?.rejectLoadStep === 'returnedAndFinalize' && (
          <Core.Button
            danger
            stringId="reject"
            onClick={onSubmit}
            type="primary"
            data-testid="reject-button"
            disabled={values?.realTimeIsLoading}
          />
        )}
      </div>
    </Styled>
  ) : (
    <Styled className={cn('finalize-actions finalize')}>
      <div className="actions">
        <Core.Button
          stringId="finalize"
          onClick={handleClick}
          type="primary"
          data-testid="finalize-button"
          disabled={values?.realTimeIsLoading}
        />
      </div>
    </Styled>
  );
};
