import React from 'react';
import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import styled from 'styled-components';

const { Column, Row, Container } = Layout;

const Styled = styled.div``;

export const MaterialComplianceMobile = ({
  airUom,
  consistenceUom,
  airTempUom,
  concreteTempUom,
  cylinderUom,
  consistenceDisabled,
  airDisabled,
}) => {
  const {
    Components: {
      ConsistenceValue,
      ConsistenceIsCompliant,
      AirContentValue,
      AirContentIsCompliant,
      DateTest,
      AirTempValue,
      ConcreteTempValue,
      CylinderNumber,
      AdditionalInfo,
    },
  } = Form.useFormContext();

  return (
    <Styled className={cn('material-compliance')}>
      <Container>
        <Column>
          <Row>
            <Column>
              <ConsistenceValue uomCode={consistenceUom} />
            </Column>
          </Row>
          <Row style={{ padding: '0px 0px 20px 0px' }}>
            <Column>
              <ConsistenceIsCompliant disabled={consistenceDisabled} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AirContentValue uomCode={airUom} />
            </Column>
          </Row>
          <Row style={{ padding: '0px 0px 20px 0px' }}>
            <Column>
              <AirContentIsCompliant disabled={airDisabled} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AirTempValue uomCode={airTempUom} />
            </Column>
          </Row>
          <Row>
            <Column>
              <ConcreteTempValue uomCode={concreteTempUom} />
            </Column>
          </Row>
          <Row>
            <Column>
              <CylinderNumber uomCode={cylinderUom} />
            </Column>
          </Row>
          <Row>
            <Column>
              <AdditionalInfo rows={3} validationText={false} />
            </Column>
          </Row>
          <Row>
            <Column>
              <DateTest />
            </Column>
          </Row>
        </Column>
      </Container>
    </Styled>
  );
};
