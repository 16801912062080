import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../../MasterDataProvider';

const { Row } = Layout;

const Styled = styled(Layout.Column)`
  &&& {
    display: flex;

    .main {
      flex: 1;
      overflow: auto;
    }

    .actions {
      margin-top: 0;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
      column-gap: 10px;
    }
  }
`;

export const ListSectionMobile = ({
  requirementFulfilled,
  CustomListComponent,
  paths,
  onClick,
  autoEdit,
  buttonWaterReleaseSignature,
}) => {
  const {
    Components: { AddButton, ListComponent, RequiredIndicator },
    parentFormContext: { values: parentValues },
  } = Form.useFormContext();

  const companySetup = useCompanySetup();

  const addButtonDisabled = React.useMemo(
    () => parentValues?.lineItems?.length >= companySetup.data?.maxLineItems - 1,
    [companySetup.data?.maxLineItems, parentValues?.lineItems?.length]
  );

  const renderButtonWaterReleaseSignature = () => {
    if (buttonWaterReleaseSignature) {
      return buttonWaterReleaseSignature;
    }

    return null;
  };

  return (
    <Styled className={cn('list')} style={{ overflow: 'visible' }}>
      <div className={cn('main')}>
        <Row style={{ alignItems: 'flex-end', flexShrink: '0' }}>
          <div className={cn('left')}>
            <RequiredIndicator disabled={requirementFulfilled} />
          </div>
        </Row>
        <div className={cn('list-section')}>
          <ListComponent
            Component={CustomListComponent}
            onClick={onClick}
            hideWhenEmpty
            paths={paths}
            autoEdit={autoEdit}
          />
        </div>
      </div>
      <div className={cn('actions')}>
        {renderButtonWaterReleaseSignature()}
        <AddButton onClick={onClick} disabled={addButtonDisabled} />
      </div>
    </Styled>
  );
};
