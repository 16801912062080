import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const { Column, Row, Container } = Layout;
const { Translate } = Localization;

const Styled = styled.div`
  .sensorUnable {
    color: #ff0000;
  }
`;

export const ReturnedMaterialsMobile = ({
  mixInfo,
  concreteOnTruckUom,
  disabled,
  options,
  reasonCodeSetup,
  sensorUnable,
  sourceOptions,
  showSource,
  showRejectLoadButton,
  onClickRejection,
  isLoading,
}) => {
  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, DriverDidNotAdd, ReturnConcreteSource },
  } = Form.useFormContext();

  return (
    <Styled className={cn('returned-materials')}>
      <Core.Spinner spin={isLoading}>
        <Column>
          <Row>
            <Container flex={1}>
              <Column>
                <div className={cn('body')}>
                  <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                  <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
                </div>
                <div className={cn('body')}>
                  <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
                  <span data-testid="loadedQtyValue"> {mixInfo.loadedQty} </span>
                </div>
              </Column>
            </Container>
          </Row>
          <Row>
            <Column>
              <ReturnConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
            </Column>
          </Row>
          {sensorUnable && (
            <Typography.Subtitle className={cn('sensorUnable')}>
              <Translate stringId="sensorUnable" />
            </Typography.Subtitle>
          )}
          {options?.length > 0 && (
            <Row>
              <Column>
                <ReturnReason
                  options={options}
                  busy={reasonCodeSetup.isLoading}
                  showSearch={false}
                  disabled={disabled}
                />
              </Column>
            </Row>
          )}
          {showSource && (
            <Row>
              <Column>
                <ReturnConcreteSource options={sourceOptions} disabled={true} />
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              <DriverDidNotAdd />
            </Column>
          </Row>
          {showRejectLoadButton && (
            <Row>
              <Column>
                <Core.Button
                  danger
                  stringId="reject"
                  onClick={onClickRejection}
                  type="primary"
                  data-testid="reject-button"
                />
              </Column>
            </Row>
          )}
        </Column>
      </Core.Spinner>
    </Styled>
  );
};
