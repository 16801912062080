import { css } from 'styled-components';

export default css`
  padding-bottom: 24px;
  padding-top: 12px;
  .comment {
    textarea {
      width: 85%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .comment-footer {
    text-align: right;
  }

  .actions {
    @media (max-width: 410px) {
      margin-top: 115px;
    }
    @media (min-width: 411px) {
      float: right;
      padding: 65px 0 20px 0;
    }
  }

  .recipients {
    margin: 28px 0 0 38vw;
    @media (max-width: 800px) {
      margin-left: 30vw;
    }
    @media (max-width: 650px) {
      margin: 83px 0 0 0;
    }
    @media (max-width: 410px) {
      margin-top: 125px;
    }

    span {
      padding-top: 8px;
    }

    .iconValid {
      font-size: 20px;
      color: #207f3b;
    }

    .iconInvalid {
      font-size: 20px;
      color: #d8222f;
    }
  }

  .privacy-policy-link {
    margin-top: 20px;
    font-weight: 500;
    @media (max-width: 410px) {
      margin-top: -130px;
    }
  }

  //.signature-outer {
  //  width: 100%;
  //  position: relative;
  //  margin-left: auto;
  //  margin-right: auto;
  //  padding-top: 20%;
  //  .signature-inner {
  //    border: 1px solid #ccc;
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    right: 0;
  //    bottom: 0;
  //    background-color: #fff;
  //    overflow: hidden;
  //  }
  //}
`;
