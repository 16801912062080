import styled from '@emotion/styled';
import { css } from 'styled-components';

export const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div.overview {
    margin: 0 0.5rem;
    flex-grow: 1;
    scroll-snap-type: x proximity;

    &.overview.scroller > div {
      scroll-snap-align: start;
      scroll-margin-left: 15px;
    }
  }

  .directional-button {
    height: unset;
    font-size: unset;
  }
`;
