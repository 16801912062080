import { Auth, User } from 'connex-cds';
import { findIndex } from 'lodash';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from './menu-config';

export const IFrameAuthenticated = ({ children }) => {
  const { entityRef } = useParams();
  const UserPermissions = User.usePermissions();

  const sidebarOptions = menuConfig?.sidebarOptions?.reduce((acc, el, pos) => {
    acc.push(el);
    return acc;
  }, []);

  const tempMenuConfig = { menuLogo: menuConfig.menuLogo, sidebarOptions: sidebarOptions };

  const newMenu = useCallback(() => {
    const setupIndex = findIndex(tempMenuConfig?.sidebarOptions, { id: 'setup' });
    const hideSetupMenuOption = [!UserPermissions?.isPlatformAdmin, setupIndex >= 0].every(Boolean);
    if (hideSetupMenuOption) {
      tempMenuConfig?.sidebarOptions?.splice(setupIndex, 1);
      return tempMenuConfig;
    }
    return tempMenuConfig;
  }, [menuConfig, UserPermissions?.isPlatformAdmin]);

  return (
    <>
      <Auth.IFrameAuthenticated entityRef={entityRef} menuConfig={newMenu()} />
    </>
  );
};
