import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Localization, Layout, Core, Typography } from 'connex-cds';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTicketContext } from '../../../TicketContext';
import { StyledTextarea, desktopStyle } from './style';
import { Products } from '../acceptance/costAndPayments/products/Products';
import { Summary } from '../acceptance/costAndPayments/summary/Summary';
import getProducts from '../../../getProducts';

const { Container, Column, Row } = Layout;
const { useTranslateMessage } = Localization;

const Styled = styled.div`
  ${desktopStyle}
`;

const testId = 'paymentMethodGrid';

export const PaymentMethodDesktop = () => {
  const { ticket, paymentMethod } = useTicketContext();
  const { setFieldValue, values } = Form.useFormContext();
  const translateMessage = useTranslateMessage();

  const handleChange = React.useCallback(
    ({ target: { value, name } }) => {
      setFieldValue(name, name === 'isExpectedPaymentMethod' ? value !== 'other' : value);
    },
    [setFieldValue]
  );

  const handleAmountChange = React.useCallback(
    event => {
      setFieldValue('paymentAmount', event);
    },
    [setFieldValue]
  );

  return (
    <Styled className={cn('payment-method-desktop')}>
      <Container className={cn('payment-method-options')}>
        <Row>
          <Column>
            <Core.Input
              data-testid={`${testId}-amount-input`}
              name="paymentAmount"
              type="number"
              onChange={handleAmountChange}
              value={values?.paymentAmount}
              placeholder={translateMessage('amount')}
            />
            <Typography.Body>{translateMessage('paymentOptions')}</Typography.Body>
            <RadioGroup
              name="isExpectedPaymentMethod"
              labelStringId="paymentMethod"
              defaultValue="expected"
              value={values?.isExpectedPaymentMethod ? paymentMethod?.id : 'other'}
              onChange={handleChange}
            >
              <FormControlLabel
                value={paymentMethod?.id}
                control={<Radio data-testid={`${testId}-expected-radio`} />}
                label={paymentMethod?.name}
              />
              <FormControlLabel
                value="other"
                control={<Radio data-testid={`${testId}-other-radio`} />}
                label={translateMessage('otherPaymentMethod')}
              />
            </RadioGroup>
          </Column>
          <Column>
            <StyledTextarea
              data-testid={`${testId}-additional-textarea`}
              name="paymentAdditionalInfo"
              value={values?.paymentAdditionalInfo}
              placeholder={translateMessage('typeAdditionalInfo')}
              minRows={13}
              onChange={handleChange}
            ></StyledTextarea>
          </Column>
        </Row>
      </Container>
      <Products productsData={getProducts(ticket?.lineItems, values?.lineItems, false)} values={values}></Products>
      <Summary
        products={getProducts(ticket?.lineItems, values?.lineItems, false)}
        orderPricing={ticket?.priceSummary}
        pricing={values?.pricing}
        paymentAmount={values?.paymentAmount}
        paymentMethod={values?.isExpectedPaymentMethod ? paymentMethod?.id : 'other'}
      />
    </Styled>
  );
};
