import { Form, Localization } from 'connex-cds';
import React from 'react';
import { useCompanySetup } from '../../../../MasterDataProvider';

const testId = 'activityGrid-water';

export const WaterAdd = () => {
  const translateMessage = Localization.useTranslateMessage();

  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  if (!values?.waterAddedEvents?.length) {
    return null;
  }

  return values.waterAddedEvents
    .filter(
      waterAddedEvent =>
        !waterAddedEvent.driverDidNotAdd || (companySetup?.data?.isWaterMandatory && waterAddedEvent.driverDidNotAdd)
    )
    .map((waterAddedEvents, index) => {
      const key = `SETUP_REASON-CODE_${waterAddedEvents?.reason?.id}`.replace(/\s/gi, '_').toUpperCase();
      const translation = translateMessage(key);

      return (
        <tr>
          <td data-testid={`${testId}-row-${index}-activity-value`}>
            <Localization.Translate stringId="waterAdded" data-testid="returnedConcrete-label" />
          </td>
          <td data-testid={`${testId}-row-${index}-quantity-value`}>
            <Localization.Uom uom={waterAddedEvents.quantity} />
          </td>
          <td data-testid={`${testId}-row-${index}-concrete-value`}>
            <Localization.Uom uom={waterAddedEvents.concreteOnTruck} />
          </td>
          <td data-testid={`${testId}-row-${index}-reason-value`}>
            {translation && translation !== key ? translation : waterAddedEvents?.reason?.description}
          </td>
        </tr>
      );
    });
};
