import { Form, Layout, Core, Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from '../../../MasterDataProvider';
import Comments from '../../../ticket-details/comments/Comments';
import DriverInstructions from '../../../ticket-details/driver-instructions/DriverInstructions';
import { LoadingUnloadingPoints } from '../../../ticket-details/logistical-info/loading-unloading-points/LoadingUnloadingPoints';
import { MaterialSummary } from '../../../ticket-details/logistical-info/material-summary/MaterialSummary';
import StatusTimes from '../../../ticket-details/logistical-info/StatusTimes';
import { Tickets } from '../../../ticket-details/ticket-list/Tickets';

import { useTicketContext } from '../../../TicketContext';
import UnloadingMethodForm from '../../../UnloadingMethodForm';
import { find } from 'lodash';
import DamageWaiver from './damage-waiver/DamageWaiver';

const { Column, Row, Container } = Layout;

const Styled = styled.div``;

export const TicketDetailDesktop = ({ activeFormsAndWaivers, isLoading }) => {
  const companySetup = useCompanySetup();
  const { ticket } = useTicketContext();
  const { setFieldValue, values } = Form.useFormContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();

  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const unloadingMethodEnabled = React.useMemo(() => {
    if (!companySetup?.data?.isUnloadingMethodEnabled) {
      return false;
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isUnloadingMethodEnabled !== false &&
        vehicleTypeData?.isUnloadingMethodEnabled !== true
      ) {
        return false;
      }
    }
    return ticket?.customData?.ticketType !== 'PMP';
  }, [companySetup?.data?.isUnloadingMethodEnabled, ticket?.customData?.ticketType, vehicleTypeData]);

  const disableDamageWaiverButton = React.useMemo(() => {
    return values?.damageWaiverDeclined || values?.damageWaiverSignature?.image;
  }, [values?.damageWaiverDeclined, values?.damageWaiverSignature]);

  const handleDamageWaiverClick = React.useCallback(() => {
    openDrawer({
      onClose: () => {
        setFieldValue('damageWaiverDeclined', '');
        setFieldValue('damageWaiverSignature', {
          signerName: '',
        });
        closeDrawer();
      },
      titleStringId: 'damageWaiver',
      component: <DamageWaiver />,
      width: '100%',
    });
  }, [closeDrawer, openDrawer, setFieldValue, values?.damageWaiverSignature]);

  const showTicketList = React.useMemo(() => {
    return Boolean(vehicleTypeData?.isTicketListEnabled) && companySetup?.data?.isTicketListEnabled;
  }, [vehicleTypeData, companySetup?.data?.isTicketListEnabled]);

  return (
    <Styled className={cn('ticket-detail-desktop')}>
      <Core.Spinner spin={isLoading}>
        <Column>
          <Row flex={1} className="content-row">
            <Column flex={1} className="">
              <MaterialSummary />
              <LoadingUnloadingPoints data={values} />
              {companySetup?.data?.isDriverCommentEnabled && (
                <Container style={{ padding: '15px' }}>
                  <Comments />
                </Container>
              )}
              {unloadingMethodEnabled && (
                <Container style={{ padding: '10px', paddingBottom: '5px' }}>
                  <UnloadingMethodForm />
                </Container>
              )}
              {showTicketList && <Tickets isDLV={ticket?.customData?.ticketType === 'DLV'} ticketId={ticket?.id} />}
            </Column>
            <Column flex={1}>
              <StatusTimes />
              <Container style={{ padding: '10px', flex: '1' }}>
                <DriverInstructions data={values} />
                <Core.Button
                  type="primary"
                  stringId="damageWaiver"
                  onClick={handleDamageWaiverClick}
                  data-testid="damageWaiver-button"
                  disabled={disableDamageWaiverButton}
                  hidden={!companySetup?.data?.isDamageWaiverEnabled || activeFormsAndWaivers?.length === 0}
                />
              </Container>
            </Column>
          </Row>
        </Column>
      </Core.Spinner>
    </Styled>
  );
};
