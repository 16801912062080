import { Form, Drawer, Responsive, Localization } from 'connex-cds';
import React from 'react';
import { useTicketContext } from '../../../../TicketContext';
import { RejectTicket } from './reject/RejectTicket';
import { FinalizeActionsMobile } from './FinalizeActionsMobile';
import { FinalizeActionsDesktop } from './FinalizeActionsDesktop';
import {
  useCompanySetup,
  useFormsAndWaiversSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../../MasterDataProvider';
import { TermsAndConditionsFlow } from './accept/terms-and-conditions/TermsAndConditionsFlow';
import { AcceptanceSignature } from './accept/AcceptanceSignature';
import { activeFormsAndWaiversArray } from '../../../../getActiveForms';
import { cleanupLineItems } from '../../../../../../../../util/cleanupLineItems';
import { mobileTicket } from '../../../../../../../../api';
import { Logger } from '../../../../../../../../util/log/logger';
import { useParams } from 'react-router-dom';

export const FinalizeActions = ({ ticketId, flow }) => {
  const { translateObjects } = Localization.useTranslateObjects();
  const translateMessage = Localization.useTranslateMessage();
  const { userLocale } = Localization.useLocalizationContext();
  const { setFieldValue, errors, values } = Form.useFormContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { finalized, setFinalized, submitted, refreshTicket, ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const { entityRef } = useParams();

  const handleAcceptCancel = React.useCallback(() => {
    setFieldValue('signature', undefined);
    setFieldValue('termsAndConditionsAccepted', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const handleRejectCancel = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'TERMS',
        formsAndWaiversSetup,
        vehicleSetup,
        vehicleTypeSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, vehicleTypeSetup, companySetup, translateObjects, userLocale]
  );

  const handleAcceptClick = React.useCallback(() => {
    openDrawer({
      onClose: handleAcceptCancel,
      titleStringId:
        !companySetup.data?.isTermsAndConditionsEnabled || activeFormsAndWaivers?.length === 0
          ? 'customerSignature'
          : 'termsAndConditions',
      component:
        !companySetup.data?.isTermsAndConditionsEnabled || activeFormsAndWaivers?.length === 0 ? (
          <AcceptanceSignature ticketId={ticketId} onCancel={handleAcceptCancel} />
        ) : (
          <TermsAndConditionsFlow
            ticketId={ticketId}
            handleAcceptCancel={handleAcceptCancel}
            activeFormsAndWaivers={activeFormsAndWaivers}
          />
        ),
      width: '100%',
    });
  }, [handleAcceptCancel, openDrawer, ticketId, companySetup]);

  const handleRejectClick = React.useCallback(() => {
    openDrawer({
      onClose: handleRejectCancel,
      titleStringId: 'customerRejection',
      component: <RejectTicket onCancel={handleRejectCancel} />,
      width: '700px',
    });
  }, [handleRejectCancel, openDrawer]);

  const updateTicket = async ({ entityRef, ticket }) => {
    try {
      const result = await mobileTicket.updateTicket({ entityRef, ticket });
      Logger.debug(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/datastore/DataStore.js - updateTicket Success: ${JSON.stringify(
          result
        )}`
      );
      return result;
    } catch (error) {
      Logger.error(
        `Mobile Ticket - frontend/src/views/apps/entity-ref/mobile-ticket/datastore/DataStore.js - updateTicket Error: ${error.message}`
      );
      return Promise.reject(error);
    }
  };

  const updateAndRefresh = React.useCallback(async () => {
    const result = await updateTicket({ entityRef, ticket: values });

    if (result?.error || result === undefined || JSON.stringify(result) === '{}') {
      setFieldValue('realTimeIsLoading', false);
      setFieldValue('driverAcceptanceReason', undefined);
      return;
    }

    await refreshTicket(entityRef);

    setFieldValue('realTimeIsLoading', false);
    setFieldValue('driverAcceptanceReason', undefined);
  }, [entityRef, refreshTicket, setFieldValue, values]);

  const handleClick = React.useCallback(() => {
    const reason = {
      crn: 'driverFinalizedOnAcceptanceScreen',
      id: 'driverFinalizedOnAcceptanceScreen',
      description: translateMessage('driverClickedFinalize'),
    };

    setFinalized(true);
    setFieldValue('finalized', true);
    if (companySetup?.data?.isRealTimeUpdatesEnabled === true) {
      setFieldValue('realTimeIsLoading', true);
      setFieldValue('driverAcceptanceReason', reason);
    }
  }, [companySetup?.data?.isRealTimeUpdatesEnabled, setFieldValue, setFinalized, translateMessage]);

  React.useEffect(() => {
    if (values?.realTimeIsLoading) {
      updateAndRefresh();
    }
  }, [updateAndRefresh, values?.realTimeIsLoading]);

  const errorsToCheck = React.useMemo(() => cleanupLineItems(errors, values, 'ticketEvents'), [errors, values]);

  if (submitted) return null;

  return (
    <Responsive>
      <FinalizeActionsMobile
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
        errors={errorsToCheck}
      />
      <FinalizeActionsDesktop
        finalized={finalized}
        flow={flow}
        handleAcceptClick={handleAcceptClick}
        handleRejectClick={handleRejectClick}
        handleClick={handleClick}
        errors={errorsToCheck}
      />
    </Responsive>
  );
};
