import { css } from 'styled-components';
import styled from 'styled-components';

export const desktopStyle = css`
  display: flex;
  height: 100%;
  gap: 1rem;
  justify-content: flex-end;

  .added-items {
    flex: 0.3;
  }

  .batch-information {
    flex: 0.7;
  }
`;

export const mobileStyle = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .main-content {
    flex: 1;
  }

  .added-items {
    flex: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;
