import { css } from 'styled-components';

export default css`
  flex-grow: 1;
  height: 100%;

  .column {
    height: 100%;

    div:first-child {
      height: 90%;
    }
  }

  .dynamic-waiver-container {
    padding: 5px 10px;
  }

  .dynamic-waiver-value {
    padding: 10px;
    overflow: auto;
    font-family: 'Work Sans';
    white-space: pre-wrap;
  }

  .actions {
    @media (max-width: 410px) {
      margin-top: 80px;
    }

    @media (min-width: 411px) {
      float: right;
      padding: 20px 0 20px 0;
    }
  }
`;
